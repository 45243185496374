// import React from "react";
// import { useTranslation } from "react-i18next";

// type Testimonial = {
//   name: string;
//   date: string;
//   text: string;
//   image?: string;
// };

// type TestimonialsProps = {
//   testimonials: Testimonial[];
// };

// const Testimonials: React.FC<TestimonialsProps> = ({ testimonials }) => {
//   const { t } = useTranslation();

//   const reviews = t("testimonials.reviews", {
//     ns: "classes",
//     returnObjects: true,
//   }) as Testimonial[];

//   return (
//     <section className="py-10 bg-gray-50 mt-10">
//       <div className="container-fluid">
//         <div className="flex flex-col items-center">
//           <h2 className="text-2xl md:text-3xl lg:text-4xl font-semibold mb-4 text-center text-black">
//             {t("testimonials.title", { ns: "classes" })}
//           </h2>
//           <p className="text-lg text-center text-gray-600 mb-8 md:w-2/4">
//             {t("testimonials.subtitle", { ns: "classes" })}
//           </p>

//           <div className="flex flex-col gap-10 py-10  w-full md:w-4/5">
//             {reviews.map((testimonial, index) => (
//               <div
//                 key={index}
//                 className={`py-10 px-10 rounded-[66px] relative z-0 text-white ${
//                   index % 2 == 0
//                     ? "bg-main sm:w-3/4"
//                     : "bg-[#111827] sm:w-3/4 ml-auto"
//                 }`}
//               >
//                 <div className="flex flex-col gap-2 w-full sm:w-3/5">
//                   <h3 className="font-medium text-base">{testimonial.name}</h3>
//                   <p className="font-normal opacity-90">{testimonial.date}</p>
//                 </div>

//                 <p className="pt-5">{testimonial.text}</p>

//                 {/* absolute */}
//                 <div
//                   className={`absolute bottom-[-20px]  z-[-1] ${
//                     index % 2 == 0 ? "left-16" : "right-16"
//                   }`}
//                 >
//                   {index % 2 == 0 ? (
//                     <svg
//                       role="presentation"
//                       width="33px"
//                       height="24px"
//                       viewBox="0 0 33 24"
//                     >
//                       {" "}
//                       <g
//                         stroke="none"
//                         stroke-width="1"
//                         fill="none"
//                         fill-rule="evenodd"
//                       >
//                         {" "}
//                         <g
//                           transform="translate(-1244.000000, -3123.000000)"
//                           fill="#f97316"
//                         >
//                           {" "}
//                           <path d="M1255,3123c0,0-0.7,5.7-2,8.8c-1.9,4.3-7.7,13.4-7.7,13.4s9.3-2.5,18-8.8c2.6-1.9,10.4-11,12.6-13.5"></path>{" "}
//                         </g>{" "}
//                       </g>{" "}
//                     </svg>
//                   ) : (
//                     <svg
//                       role="presentation"
//                       width="33px"
//                       height="24px"
//                       viewBox="0 0 33 24"
//                     >
//                       {" "}
//                       <g
//                         stroke="none"
//                         stroke-width="1"
//                         fill="none"
//                         fill-rule="evenodd"
//                       >
//                         {" "}
//                         <g
//                           transform="translate(-1244.000000, -3123.000000)"
//                           fill="#111827"
//                         >
//                           {" "}
//                           <path
//                             d="M1275.13811,3123 C1272.95728,3125.43852 1265.08297,3134.61485 1262.52627,3136.47672 C1253.84354,3142.79977 1244.5,3145.28571 1244.5,3145.28571 C1244.5,3145.28571 1250.29058,3136.12799 1252.15133,3131.84682 C1253.51931,3128.69942 1254.18621,3123 1254.18621,3123"
//                             transform="translate(1259.819055, 3134.142857) scale(-1, 1) translate(-1259.819055, -3134.142857) "
//                           ></path>{" "}
//                         </g>{" "}
//                       </g>{" "}
//                     </svg>
//                   )}
//                 </div>
//               </div>
//             ))}
//           </div>
//         </div>
//       </div>
//     </section>
//   );
// };

// export default Testimonials;

import React, { useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Swiper as SwiperClass } from "swiper";
import { Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";

type Testimonial = {
  name?: string;
  date: string;
  text: string;
  image: string;
  rating: number;
  title?: string;
};

type TestimonialsProps = {
  testimonials: Testimonial[];
};

// const testimonials: Testimonial[] = [
//   {
//     name: "Darrell Steward",
//     date: "10/06/2024",
//     text: "I've never had a smoother experience shopping for a car online. The website's intuitive design made it easy to find exactly what I was looking for. Plus, the detailed car profiles really helped me feel confident in my purchase.",
//     image: "/path/to/image1.jpg",
//     rating: 4,
//     title: "A Breeze to Navigate",
//   },
//   // Add more testimonials here
// ];

const Testimonials: React.FC<TestimonialsProps> = ({ testimonials }) => {
  const prevRef = useRef<HTMLButtonElement>(null);
  const nextRef = useRef<HTMLButtonElement>(null);

  return (
    <section className="py-16 bg-white">
      <div className="text-center mb-8">
        <h2 className="text-3xl font-semibold text-black">
          What Our Customers Say
        </h2>
      </div>

      <div className="container-fluid relative z-0">
        <Swiper
          modules={[Navigation]}
          loop
          spaceBetween={20}
          slidesPerView={1}
          navigation={{
            prevEl: prevRef.current,
            nextEl: nextRef.current,
          }}
          className="container mx-auto flex items-center"
          onBeforeInit={(swiper: SwiperClass) => {
            // @ts-ignore
            swiper.params.navigation.prevEl = prevRef.current;
            // @ts-ignore
            swiper.params.navigation.nextEl = nextRef.current;
          }}
        >
          {testimonials.map((testimonial, index) => (
            <SwiperSlide key={index}>
              <div className="flex flex-col items-center py-10">
                <div className="flex space-x-4 mb-6">
                  {testimonials.map((t, i) => (
                    <div
                      key={i}
                      className={`w-16 h-16 rounded-full overflow-hidden ${
                        i === index ? "ring-4 ring-green-500" : ""
                      }`}
                    >
                      <img
                        src={t?.image}
                        alt={t?.name}
                        className="w-full h-full object-cover"
                      />
                    </div>
                  ))}
                </div>

                <h3 className="text-xl font-semibold mb-2">
                  {testimonial?.title}
                </h3>
                <p className="text-center text-gray-700 max-w-lg mx-auto">
                  {testimonial?.text}
                </p>

                <div className="flex items-center mt-4">
                  <img
                    src={testimonial?.image}
                    alt={testimonial?.name}
                    className="w-12 h-12 rounded-full mr-4"
                  />
                  <div>
                    <p className="font-medium">{testimonial?.name}</p>
                    <div className="flex text-yellow-500">
                      {Array.from({ length: 5 }, (_, i) => (
                        <span key={i}>
                          {i < testimonial.rating ? "★" : "☆"}
                        </span>
                      ))}
                    </div>
                    <p className="text-gray-500 text-sm">{testimonial.date}</p>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
          <button
            ref={prevRef}
            className="absolute left-10 z-10 bg-purple-600 text-white p-3 rounded-full shadow-lg hover:bg-purple-700 transition-colors top-2/4 -translate-y-2/4 sm:block hidden"
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 10 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.49976 1.0001L1.49976 7.4751C1.49976 7.60771 1.44708 7.73488 1.35331 7.82865C1.25954 7.92242 1.13236 7.9751 0.999756 7.9751C0.867148 7.9751 0.739971 7.92242 0.646203 7.82865C0.552434 7.73488 0.499756 7.60771 0.499756 7.4751L0.499756 0.500098C0.499756 0.36749 0.552434 0.240312 0.646202 0.146544C0.73997 0.0527758 0.867147 9.77356e-05 0.999756 9.77298e-05L7.99976 9.74238e-05C8.13236 9.7418e-05 8.25954 0.0527755 8.35331 0.146544C8.44708 0.240312 8.49976 0.367489 8.49976 0.500097C8.49976 0.632706 8.44708 0.759883 8.35331 0.853651C8.25954 0.947419 8.13236 1.0001 7.99976 1.0001L1.49976 1.0001Z"
                fill="white"
              />
              <path
                d="M0.645899 0.854345C0.552012 0.760459 0.499267 0.633121 0.499267 0.500345C0.499267 0.367569 0.552012 0.240232 0.645899 0.146345C0.739785 0.0524585 0.867123 -0.000286118 0.999899 -0.000286124C1.13267 -0.00028613 1.26001 0.0524585 1.3539 0.146345L9.8539 8.64634C9.90039 8.69283 9.93726 8.74802 9.96242 8.80876C9.98758 8.8695 10.0005 8.9346 10.0005 9.00034C10.0005 9.06609 9.98758 9.13119 9.96242 9.19193C9.93726 9.25267 9.90039 9.30786 9.8539 9.35434C9.80741 9.40083 9.75222 9.43771 9.69148 9.46287C9.63074 9.48803 9.56564 9.50098 9.4999 9.50098C9.43416 9.50098 9.36905 9.48803 9.30832 9.46287C9.24758 9.43771 9.19239 9.40083 9.1459 9.35434L0.645899 0.854345Z"
                fill="white"
              />
            </svg>
          </button>
          <button
            ref={nextRef}
            className="absolute right-10 z-10 bg-purple-600 text-white p-3 rounded-full shadow-lg hover:bg-purple-700 transition-colors top-2/4 -translate-y-2/4 sm:block hidden"
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 10 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.9999 1H2.5249C2.39229 1 2.26512 0.947321 2.17135 0.853553C2.07758 0.759785 2.0249 0.632608 2.0249 0.5C2.0249 0.367392 2.07758 0.240215 2.17135 0.146447C2.26512 0.0526785 2.39229 0 2.5249 0H9.4999C9.63251 0 9.75969 0.0526785 9.85346 0.146447C9.94722 0.240215 9.9999 0.367392 9.9999 0.5V7.5C9.9999 7.63261 9.94722 7.75979 9.85346 7.85355C9.75969 7.94732 9.63251 8 9.4999 8C9.36729 8 9.24012 7.94732 9.14635 7.85355C9.05258 7.75979 8.9999 7.63261 8.9999 7.5V1Z"
                fill="white"
              />
              <path
                d="M9.1459 0.146143C9.23979 0.0522566 9.36712 -0.000488281 9.4999 -0.000488281C9.63267 -0.000488281 9.76001 0.0522566 9.8539 0.146143C9.94779 0.24003 10.0005 0.367368 10.0005 0.500143C10.0005 0.632919 9.94779 0.760257 9.8539 0.854143L1.3539 9.35414C1.30741 9.40063 1.25222 9.43751 1.19148 9.46267C1.13074 9.48783 1.06564 9.50077 0.999899 9.50077C0.934155 9.50077 0.869055 9.48783 0.808316 9.46267C0.747576 9.43751 0.692387 9.40063 0.645899 9.35414C0.599411 9.30766 0.562535 9.25247 0.537376 9.19173C0.512217 9.13099 0.499268 9.06589 0.499268 9.00014C0.499268 8.9344 0.512217 8.8693 0.537376 8.80856C0.562535 8.74782 0.599411 8.69263 0.645899 8.64614L9.1459 0.146143Z"
                fill="white"
              />
            </svg>
          </button>
        </Swiper>
      </div>
    </section>
  );
};

export default Testimonials;
