import axiosInstance from "./../axios";

export interface EntrollGroup {
  groupId: string;
  isConfirmed: boolean;
}

// POST request to enroll in a group
export const enrollInGroup = async (enrollmentData: EntrollGroup): Promise<void> => {
  try {
    const response = await axiosInstance.post("/manual-purchase/group", enrollmentData);
    console.log("Enrollment successful:", response.data);
  } catch (error) {
    console.error("Enrollment failed:", error);
    throw error; // Rethrow the error if you need to handle it elsewhere
  }
};
