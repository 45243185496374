import React from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const HowItWorks: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <Navbar />
      <div className="min-h-screen bg-gray-200 flex flex-col items-center py-12">
        <h1 className="text-2xl sm:text-3xl lg:text-4xl font-bold text-gray-900 mb-8">
          {t("howItWorks.title")}
        </h1>
        <div
          className="flex flex-col gap-7 max-w-3xl w-full px-6"
          data-aos="fade-up"
        >
          {/* Step 1 */}
          <div className="bg-white p-6 rounded-lg shadow-lg flex items-start space-x-4">
            <div className="flex-shrink-0">
              <div className="bg-orange-600 text-white h-12 w-12 flex items-center justify-center rounded-full text-2xl font-bold">
                1
              </div>
            </div>
            <div>
              <h2 className="text-lg sm:text-2xl font-semibold text-gray-800">
                {t("howItWorks.step1.title")}
              </h2>
              <p className="mt-2 text-gray-600">
                {t("howItWorks.step1.description")}
              </p>
            </div>
          </div>

          {/* Step 2 */}
          <div className="bg-white p-6 rounded-lg shadow-lg flex items-start space-x-4">
            <div className="flex-shrink-0">
              <div className="bg-orange-600 text-white h-12 w-12 flex items-center justify-center rounded-full text-2xl font-bold">
                2
              </div>
            </div>
            <div>
              <h2 className="text-lg sm:text-2xl font-semibold text-gray-800">
                {t("howItWorks.step2.title")}
              </h2>
              <p className="mt-2 text-gray-600">
                {t("howItWorks.step2.description")}
              </p>
            </div>
          </div>

          {/* Step 3 */}
          <div className="bg-white p-6 rounded-lg shadow-lg flex items-start space-x-4">
            <div className="flex-shrink-0">
              <div className="bg-orange-600 text-white h-12 w-12 flex items-center justify-center rounded-full text-2xl font-bold">
                3
              </div>
            </div>
            <div>
              <h2 className="text-lg sm:text-2xl font-semibold text-gray-800">
                {t("howItWorks.step3.title")}
              </h2>
              <p className="mt-2 text-gray-600">
                {t("howItWorks.step3.description")}
              </p>
            </div>
          </div>
        </div>
        <div className="flex justify-center mt-12" data-aos="fade-up">
          <Link
            to="/signup"
            className="bg-orange-500 text-white py-3 px-8 rounded-lg shadow-lg hover:bg-orange-600 focus:outline-none focus:ring-2 focus:ring-blue-200"
          >
            {t("howItWorks.getStarted")}
          </Link>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default HowItWorks;
