import React, { useEffect, useState } from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import ReviewsSection from "./ReviewsSection";
import { useNavigate, useParams } from "react-router-dom";
import { getOneClass, ClassData } from "../common/api/path/ClassPath";
import { Category } from "../common/api/path/CategoryPath";
import { enrollInClass } from "../common/api/path/EnrollClass";
import { getOneGroup } from "../common/api/path/GroupPath";
import { enrollInGroup } from "../common/api/path/EntrollGroup";
import { getFilesUrl } from "../common/helpers/utils";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import FreeTrialModal from "./FreeTrialModal";
import ContactDetailsModal from "./ContactDetailsModal";
const MySwal = withReactContent(Swal);

interface ClassDetailsProps {
  title: string;
  instructor: string;
  rating: number;
  totalReviews: number;
  price: string;
  weeklyClass: string;
  duration: string;
  ageRange: string;
  learnersPerClass: string;
  classDescription: string;
  schedule: ScheduleItem[];
}

interface ScheduleItem {
  day: string;
  time: string;
  nextMeeting: string;
  teacher: string;
  seatsFilled: number;
  totalSeats: number;
}

const ClassDetails: React.FC = () => {
  const [activeTab, setActiveTab] = useState<string>("about");
  const navigate = useNavigate();
  const { id, type } = useParams<{ id: string; type: string }>(); // Get the dynamic 'id' from the URL
  const [classDetailsData, setClassDetails] = useState<ClassData | null>(null);
  const [categoryData, setCategoryData] = useState<Category | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [isFreeTrialModalOpen, setIsFreeTrialModalOpen] =
    useState<boolean>(false);
  const [isContactModalOpen, setIsContactModalOpen] = useState<boolean>(false);

  const closeFreeTrialModal = () => setIsFreeTrialModalOpen(false);

  const openContactModal = () => {
    closeFreeTrialModal();
    setIsContactModalOpen(true);
  };

  const closeContactModal = () => setIsContactModalOpen(false);

  useEffect(() => {
    const fetchClassDetails = async () => {
      try {
        let response;
        if (type == "class") {
          response = await getOneClass(id);
        } else {
          response = await getOneGroup(id);
        }
        setClassDetails(response);
        setLoading(false);
      } catch (err) {
        console.error(err);
        setError("Failed to load class details.");
        setLoading(false);
      }
    };

    fetchClassDetails();
  }, [id]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  const classDetails: ClassDetailsProps = {
    title:
      "Acting the Scene: Learn to Analyze, Deliver & Win the Part! ESL Friendly",
    instructor: "Elizabeth Green",
    rating: 4.6,
    totalReviews: 1356,
    price: "$8 weekly",
    weeklyClass: "1x per week",
    duration: "25 mins",
    ageRange: "Ages: 8-12",
    learnersPerClass: "4-8 learners per class",
    classDescription: `
      Lights, camera, action!
      Join us for a fun and energizing class to practice your acting skills with scene-reading!
      Learners will be provided a script at the start of class and will do a "cold" read.
      Then we will discuss what is happening in the scene and practice acting the part.
    `,
    schedule: [
      {
        day: "Saturdays",
        time: "4:30 – 4:55 PM",
        nextMeeting: "Sep 14",
        teacher: "Garrett Young",
        seatsFilled: 3,
        totalSeats: 8,
      },
    ],
  };

  const teacherCredentials = [
    { label: "1 teacher has a teaching certificate", count: 1 },
    { label: "11 teachers have a Bachelor's degree", count: 11 },
    { label: "1 teacher has an Associate's degree", count: 1 },
  ];

  const learningGoals = [
    "Understanding character goals and motivations.",
    "Understanding the essence and importance of conflict/contrast.",
    "Keys to good showmanship.",
    "How the scene relates to the play/movie/story.",
    "Tips on how to commit to the character/lines.",
    "How to be a good listener and therefore a good 'reactor'.",
  ];

  const otherDetails = [
    {
      title: "Parental Guidance",
      description:
        "This course is rated PG. Students need to be involved in this course: screens on and unmuted.",
      icon: "👨‍👩‍👧",
    },
    {
      title: "Pre-Requisites",
      description:
        "Basic reading skills are required, as learners will be reading from script pages.",
      icon: "📖",
    },
    {
      title: "External Resources",
      description:
        "Learners will not need any additional apps or websites beyond standard Outschool tools.",
      icon: "🌐",
    },
  ];

  const tabButtons = [
    {
      id: "about",
      name: "About",
    },
    {
      id: "learning-overview",
      name: "Learning Overview",
    },
    {
      id: "schedule",
      name: "Schedule",
    },
    {
      id: "teacher",
      name: "Teacher",
    },
  ];

  const renderTabContent = () => {
    switch (activeTab) {
      case "about":
        return (
          <div className="mt-6">
            <h2 className="text-2xl font-semibold">Class Description</h2>
            <p className="text-gray-700 mt-4">
              {classDetails.classDescription}
            </p>
          </div>
        );
      case "learning-overview":
        return (
          <div className="mt-6">
            <h2 className="text-2xl font-semibold">Learning Goals</h2>
            {/* Learning Goals content here */}
            <div className="bg-yellow-100 p-4 rounded-lg shadow-md mt-4">
              <ul className="list-disc ml-5 space-y-2 text-gray-700">
                {learningGoals.map((goal, index) => (
                  <li key={index}>{goal}</li>
                ))}
              </ul>
            </div>
          </div>
        );
      case "schedule":
        return (
          <div className="mt-6">
            <h2 className="text-2xl font-semibold">Schedule</h2>
            {/* Schedule content here */}
            <div className="border-t mt-4 pt-4">
              {classDetails.schedule.map((item, index) => (
                <div
                  key={index}
                  className="flex justify-between items-center mb-4"
                >
                  <div>
                    <p className="font-bold">{item.day}</p>
                    <p>{item.time}</p>
                    <p className="text-gray-600">
                      Next meeting: {item.nextMeeting}
                    </p>
                    <p className="text-gray-600">Taught by: {item.teacher}</p>
                    <p className="text-gray-600">
                      Seats filled: {item.seatsFilled}/{item.totalSeats}
                    </p>
                  </div>
                  <button className="bg-purple-600 text-white py-2 px-6 rounded-lg hover:bg-purple-700 transition">
                    Subscribe
                  </button>
                </div>
              ))}
              <div className="mt-4">
                <p className="text-gray-500">
                  Don't see what you're looking for?
                </p>
                <button className="border border-gray-400 text-gray-700 py-2 px-4 rounded-lg mt-2 hover:bg-gray-100 transition">
                  Request Another Time
                </button>
              </div>
            </div>
          </div>
        );
      case "teacher":
        return (
          <div className="mt-6">
            <h2 className="text-2xl font-semibold">Offered by</h2>
            <div className="flex items-center mt-4 space-x-4">
              <img
                src="https://www.example.com/instructor.jpg" // Replace with actual image URL
                alt="Instructor"
                className="w-20 h-20 rounded-full"
              />
              <div>
                <p className="font-bold text-lg">{classDetails.instructor}</p>
                <p className="text-sm text-gray-500">Joined March, 2020</p>
                <div className="flex space-x-2 text-yellow-500">
                  <span>★ {classDetails.rating}</span>
                  <span className="text-gray-600">
                    {classDetails.totalReviews} reviews
                  </span>
                </div>
                <div className="mt-4 space-x-2">
                  <button className="border border-gray-400 text-gray-600 py-2 px-4 rounded-lg hover:bg-gray-100 transition">
                    Profile
                  </button>
                  <button className="border border-gray-400 text-gray-600 py-2 px-4 rounded-lg hover:bg-gray-100 transition">
                    Ask a Question
                  </button>
                </div>
              </div>
            </div>

            {/* Teacher Expertise and Credentials */}
            <div className="mt-8">
              <h3 className="text-lg font-semibold">
                Teacher expertise and credentials
              </h3>
              <ul className="list-none space-y-4 mt-4">
                {teacherCredentials.map((credential, index) => (
                  <li key={index} className="flex items-center space-x-2">
                    <span role="img" aria-label="Badge">
                      🏅
                    </span>
                    <span>{credential.label}</span>
                  </li>
                ))}
              </ul>
            </div>

            {/* Teacher Bio */}
            <div className="mt-6">
              <p className="text-gray-700">
                Garrett Young has a Bachelor’s Degree in theater and has worked
                as a professional actor several times over. Garrett is part of
                the Elizabeth Green Communications Organization—focused on
                building confidence in public speaking situations.
              </p>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  // class Entrollment
  const confirmEnrollFunction = async (classId: any) => {
    const result = await MySwal.fire({
      title: "Are you sure?",
      text: "Do you want to enroll in this class?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, enroll me!",
    });

    if (result.isConfirmed) {
      try {
        const response =
          type == "class"
            ? await enrollInClass({
                classId,
                isConfirmed: true,
              })
            : await enrollInGroup({ groupId: classId, isConfirmed: true });

        await MySwal.fire({
          icon: "success",
          title: "Enrolled!",
          text: "You have successfully enrolled in the class.",
        });
        navigate("/dashboard");
      } catch (error) {
        await MySwal.fire({
          icon: "error",
          title: "Enrollment Failed",
          text: "There was a problem enrolling in the class. Please try again.",
        });
      }
    }
  };

  return (
    <>
      <Navbar />

      <div className="container mx-auto py-8">
        <div className="flex flex-col lg:flex-row justify-between lg:items-start">
          {/* Left Side: Class Details */}
          <div className="lg:w-2/3">
            <h3 className="text-3xl font-bold mb-4">
              {classDetailsData?.title}
            </h3>

            <div className="flex items-center space-x-2 text-gray-600 md:flex-nowrap flex-wrap">
              <div className="w-full md:w-2/4">
                <p>{classDetailsData?.description}</p>
              </div>
              <div className="w-full md:w-2/4">
                <img
                  src={getFilesUrl(classDetailsData?.imageUrl)}
                  alt=""
                  className="w-full h-full object-cover"
                />
              </div>
            </div>
          </div>

          {/* Right Side: Price and Details */}
          <div className="lg:w-1/3 lg:ml-10 bg-white shadow-lg rounded-lg p-6 mt-6 lg:mt-0">
            <p className="text-lg font-semibold">Live Class</p>
            <p className="text-2xl font-bold">{classDetailsData?.price}$</p>
            <p>{classDetails.weeklyClass}</p>
            <p>{classDetails.duration} per week</p>
            <p>{classDetails.ageRange}</p>
            <p>{classDetails.learnersPerClass}</p>
            <button
              onClick={() => {
                confirmEnrollFunction(classDetailsData?._id);
              }}
              className="bg-purple-600 text-white py-3 px-6 mt-4 rounded-lg hover:bg-purple-700 transition"
            >
              Enroll Now
            </button>

            {/* Free Trial Modal */}
            {isFreeTrialModalOpen && (
              <FreeTrialModal
                closeModal={closeFreeTrialModal}
                openContactModal={openContactModal}
              />
            )}
            {/* Contact Details Modal */}
            {isContactModalOpen && (
              <ContactDetailsModal closeModal={closeContactModal} />
            )}
          </div>
        </div>

        {/* Tabs for About, Overview, Schedule, etc. */}
        <div className="mt-8">
          <div className="flex space-x-6 border-b">
            {tabButtons.map((item, index) => (
              <button
                key={item.id}
                onClick={() => setActiveTab(item.id)}
                className={`py-2 px-4 text-gray-500 ${
                  item.id == activeTab
                    ? "text-purple-600 font-semibold border-b-2 border-purple-600"
                    : ""
                }`}
              >
                {item.name}
              </button>
            ))}
          </div>

          {/* TAB CONTENTS */}
          {renderTabContent()}
        </div>

        <ReviewsSection />
      </div>

      <Footer />
    </>
  );
};

export default ClassDetails;
