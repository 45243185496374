import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import icon1 from "./../../assets/icon-why-choose-1.svg";
import icon2 from "./../../assets/icon-why-choose-2.svg";
import icon3 from "./../../assets/icon-why-choose-3.svg";
import icon4 from "./../../assets/icon-why-choose-4.svg";
import icon5 from "./../../assets/icon-why-choose-5.svg";
import icon6 from "./../../assets/icon-why-choose-6.svg";
import bg from "./../../assets/bg-why-choose.svg";

interface pageProp {
  advantagesTitle: string;
}

const WhyChooseUs: React.FC<pageProp> = ({ advantagesTitle }) => {
  const { t, i18n } = useTranslation();

  const data = [
    {
      id: 1,
      name: t("whychooseus1title", { ns: "classes" }),
      desc: t("whychooseus1desc", { ns: "classes" }),
      icon: icon1,
    },
    {
      id: 2,
      name: t("whychooseus2title", { ns: "classes" }),
      desc: t("whychooseus2desc", { ns: "classes" }),
      icon: icon2,
    },
    {
      id: 3,
      name: t("whychooseus3title", { ns: "classes" }),
      desc: t("whychooseus3desc", { ns: "classes" }),
      icon: icon3,
    },
    {
      id: 4,
      name: t("whychooseus4title", { ns: "classes" }),
      desc: t("whychooseus4desc", { ns: "classes" }),
      icon: icon4,
    },
    {
      id: 5,
      name: t("whychooseus5title", { ns: "classes" }),
      desc: t("whychooseus5desc", { ns: "classes" }),
      icon: icon5,
    },
    {
      id: 6,
      name: t("whychooseus6title", { ns: "classes" }),
      desc: t("whychooseus6desc", { ns: "classes" }),
      icon: icon6,
    },
  ];

  return (
    <div className="py-10 sm:py-20 bg-main bg-opacity-20 my-10 sm:my-20">
      <div className="container-fluid flex flex-col gap-10 md:gap-14">
        <h4 className="text-2xl md:text-3xl lg:text-4xl font-semibold">
          {advantagesTitle}
        </h4>
        <div className="flex flex-wrap justify-center 2xl:justify-between gap-10">
          {data.map((item, index) => {
            return (
              <div
                className="w-[283px] h-[214px] flex flex-col gap-5 relative z-0"
                key={index}
                style={{
                  background: `url(${bg})`,
                  backgroundRepeat: "no-repeat",
                }}
              >
                <h5 className="pl-5 pt-6 pr-14 font-semibold">{item.name}</h5>
                <p className="px-5 opacity-80 text-sm pt-3">{item.desc}</p>

                {/* icon */}
                <img
                  src={item.icon}
                  alt="image"
                  className="w-[57px] h-[57px] rounded-full absolute top-0 right-0"
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default WhyChooseUs;
