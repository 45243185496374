import React, { useEffect, useState } from "react";
import authAxios from "../common/api/axios"; // Import your authAxios
import { ACCESS_TOKEN, baseURL } from "../common/api";
import Sidebar from "./Sidebar";
import { getPublicClasses , ClassData } from "../common/api/path/ClassPath";
import { formatDate } from "../common/helpers/utils";
import { GroupData, getPublicGroups } from "../common/api/path/GroupPath";

// interface ClassData {
//   _id?: string;
//   title: string;
//   description: string;
//   imageUrl: string;
//   startDate?: any;
// }

const Classes: React.FC = () => {
  const [classes, setClasses] = useState<ClassData[]>([]);
  const [groups, setGroups] = useState<GroupData[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  // Fetch classes data from the API
  useEffect(() => {
    const fetchClasses = async () => {
      try {
        const classData = await getPublicClasses(); // Using the service to fetch data
        const groupData = await getPublicGroups(); // Using the service to fetch data
        setClasses(classData);
        setGroups(groupData);
        setLoading(false);
      } catch (err) {
        console.error(err);
        setError("Failed to load classes.");
        setLoading(false);
      }
    };

    fetchClasses();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="min-h-screen bg-gray-100">
      {/* Sidebar */}
      <div className="flex">
        <div className="w-64 bg-blue-700 min-h-screen text-white p-6">
          <h2 className="text-2xl font-semibold mb-8">Classes and Groups</h2>
          <Sidebar />
        </div>

        {/* Main Content */}
        <div className="flex-1 p-6">
          <h1 className="text-3xl font-bold mb-6">Welcome Back, Student!</h1>

          {/* Main Dashboard Cards */}
          <div className="bg-white p-6 rounded-lg shadow-md w-full">
            <h3 className="text-lg font-semibold mb-4">Our Classes and Groups</h3>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5">
              {classes.map((classData) => (
                <div
                  key={classData._id}
                  className="bg-slate-100 p-4 rounded-lg shadow-md flex items-start"
                >
                  <a href={`/classdetails/${classData?._id}/class`}>
                    <img
                      src={baseURL + "/files/" + classData.imageUrl}
                      alt={classData.title}
                      className="w-20 h-20 rounded-lg object-cover mr-4"
                    />
                    <div>
                      <h4 className="font-bold text-purple-600 mb-1 mt-2">
                        {classData.title}
                      </h4>
                      <p className="text-sm text-gray-600">
                        Class • Meeting on{" "}
                        {formatDate(classData.startDate)}
                      </p>
                    </div>
                  </a>
                </div>
              ))}
              {groups.map((data) => (
                <div
                  key={data._id}
                  className="bg-red-50 p-4 rounded-lg shadow-md flex items-start"
                >
                  <a href={`/classdetails/${data?._id}/group`}>
                    <img
                      src={baseURL + "/files/" + data.imageUrl}
                      alt={data.title}
                      className="w-20 h-20 rounded-lg object-cover mr-4"
                    />
                    <div>
                      <h4 className="font-bold text-purple-600 mb-1 mt-2">
                        {data.title}
                      </h4>
                      <p className="text-sm text-gray-600">
                        Groups • Meeting on{" "}
                        {formatDate(data.startDate)}
                      </p>
                    </div>
                  </a>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Classes;
