// src/components/FAQ.tsx
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

const FAQ: React.FC = () => {
  const { t } = useTranslation();
  const [activeIndex, setActiveIndex] = useState<number | null>(null);

  const toggleAccordion = (index: number) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <section className="sm:py-10">
      <div className="container-fluid p-4 flex flex-col gap-10 md:gap-20 items-center">
        <h2 className="text-2xl md:text-3xl lg:text-4xl font-semibold text-center">
          {t("faqTitle", { ns: "classes" })}
        </h2>
        <div className="flex flex-col gap-5 w-full sm:w-4/5">
          {faqData.map((faq, index) => (
            <div key={index} className="border-b border-gray-300 pb-2 group">
              <button
                className="flex justify-between items-center w-full text-left py-3 "
                onClick={() => toggleAccordion(index)}
              >
                <span className="font-medium">
                  {t(faq.title, { ns: "classes" })}
                </span>
                <span className="text-3xl relative z-0 after:w-10 after:h-10 after:rounded-full after:bg-main after:absolute after:top-[-2px] after:-left-3 after:z-[-1] after:scale-0 after:transition-all after:duration-150 group-hover:after:scale-100 group-hover:text-white">
                  {activeIndex === index ? "−" : "+"}
                </span>
              </button>
              <div
                className={`overflow-hidden transition-all duration-300 ${
                  activeIndex === index ? "expand" : "collapse"
                }`}
              >
                {activeIndex === index && (
                  <p className="mt-2 text-gray-600">
                    {t(faq.content, { ns: "classes" })}
                  </p>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

const faqData = [
  { title: "durationTitle", content: "durationContent" },
  { title: "costTitle", content: "costTitle" },
  { title: "lessonsTitle", content: "lessonsTitle" },
  { title: "lessonDurationTitle", content: "lessonDurationTitle" },
  { title: "softwareTitle", content: "softwareTitle" },
  { title: "homeworkTitle", content: "homeworkTitle" },
];

export default FAQ;
