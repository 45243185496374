import React, { useContext, useEffect, useState } from "react";
import Sidebar from "../Sidebar";
import {
  ClassData,
  updateClass,
  getOneClass,
  deleteClass,
} from "../../common/api/path/ClassPath"; // Import createGroup function
import {
  deleteGroup,
  getOneGroup,
  updateGroup,
} from "../../common/api/path/GroupPath";
import { Category, getCategories } from "../../common/api/path/CategoryPath";
import { Context } from "../../App";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useNavigate, useParams } from "react-router-dom";
import axiosInstance from "../../common/api/axios";
import { formaterDate, getFilesUrl } from "../../common/helpers/utils";

const MySwal = withReactContent(Swal);
interface ImageUploadResponse {
  src: string;
}

const EditClass: React.FC = () => {
  const { id, state } = useParams<{ id: string; state: string }>();
  const [categoryData, setCategoryData] = useState<Category[] | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const { user } = useContext(Context);
  const navigate = useNavigate();
  const [selectedType, setSelectedType] = useState<"class" | "group" | any>(
    state
  );
  const [imageFile, setImageFile] = useState<File | null>(null);
  const [formData, setFormData] = useState<ClassData>({
    title: "",
    description: "",
    imageUrl: "",
    startDate: "",
    category: "",
    link: "",
    price: 0,
    isPublished: "true",
    isActive: "",
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);

        // Fetch either class or group details based on the 'state' param
        if (state === "class") {
          const classData = await getOneClass(id);
          setFormData({
            ...classData,
            startDate: formaterDate(classData.startDate),
          });
        } else {
          const groupData = await getOneGroup(id);
          setFormData({
            ...groupData,
            startDate: formaterDate(groupData.startDate),
          });
        }

        const categories = await getCategories();
        setCategoryData(categories);
        setLoading(false);
      } catch (err) {
        console.error(err);
        setError("Failed to load details.");
        setLoading(false);
      }
    };

    fetchData();
  }, [id, state]);

  const handleChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    const { name, value } = e.target;
    const newValue = name === "price" ? parseFloat(value) : value;
    setFormData((prevData) => ({
      ...prevData,
      [name]: newValue,
    }));
  };

  const handleImageUpload = async (file: File): Promise<string> => {
    const formData = new FormData();
    formData.append("name", "file");
    formData.append("file", file);
    try {
      const response = await axiosInstance.post<ImageUploadResponse>(
        "/files",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return response.data.src; // Now TypeScript knows response.data has a src property
    } catch (error) {
      console.error("Image upload failed:", error);
      throw new Error("Failed to upload image");
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      let imageUrl = formData.imageUrl; // Use the existing image if none is uploaded

      // If an image is selected, upload it first
      if (imageFile) {
        imageUrl = await handleImageUpload(imageFile);
      }

      const updatedData = {
        ...formData,
        imageUrl,
        teacherId: user?._id,
        isActive: "true",
      };

      // Check if it's a class or group and send the respective update request
      if (state === "class") {
        const updatedClass = await updateClass(id, updatedData);
        console.log("Class updated successfully:", updatedClass);
        MySwal.fire(
          "Successfully Updated",
          "You have successfully updated the class!",
          "success"
        );
      } else {
        const updatedGroup = await updateGroup(id, updatedData);
        console.log("Group updated successfully:", updatedGroup);
        MySwal.fire(
          "Successfully Updated",
          "You have successfully updated the group!",
          "success"
        );
      }

      navigate("/dashboard");
    } catch (error: any) {
      console.error("Error updating class or group:", error);
      setError(error.response?.data?.message || "Something went wrong");
    }
  };

  const handleDelete = async () => {
    const result = await MySwal.fire({
      title: "Are you sure?",
      text: "Do you want to delete ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Delete !",
    });

    if (result.isConfirmed) {
      try {
        const response =
          state === "class" ? await deleteClass(id) : await deleteGroup(id);

        await MySwal.fire({
          icon: "success",
          title: "Deleted!",
          text: `You have deleted this ${state}`,
        });
        navigate("/dashboard");
      } catch (error: any) {
        await MySwal.fire({
          icon: "error",
          title: "Deleted Failed",
          text: error.response?.message
            ? "There was a problem delete. Please try again."
            : error.response?.data?.message,
        });
      }
    }
  };

  return (
    <div className="min-h-screen bg-gray-100">
      {/* Sidebar */}
      <div className="flex">
        <div className="w-64 bg-blue-700 min-h-screen text-white p-6">
          <h2 className="text-2xl font-semibold mb-8">Classes</h2>
          <Sidebar />
        </div>

        {/* Main Content */}
        <div className="flex-1 p-6">
          <h1 className="text-3xl font-bold mb-6">Welcome Back, Teacher!</h1>

          {/* Form for Adding Class */}
          <div className="bg-white p-6 rounded-lg shadow-md w-full">
            <h3 className="text-lg font-semibold mb-4">Add Class or Group</h3>
            {error ? (
              <p className="p-3 bg-red-400 rounded-lg mb-3">{error}</p>
            ) : null}

            <form onSubmit={handleSubmit}>
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">
                  Title
                </label>
                <input
                  type="text"
                  name="title"
                  value={formData.title}
                  onChange={handleChange}
                  className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                  required
                />
              </div>

              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">
                  Description
                </label>
                <textarea
                  name="description"
                  value={formData.description}
                  onChange={handleChange}
                  className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                  required
                ></textarea>
              </div>

              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">
                  Link
                </label>
                <input
                  type="text"
                  name="link"
                  value={formData.link}
                  onChange={handleChange}
                  className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                  required
                />
              </div>

              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">
                  Image File (If you want to change image, upload new image.)
                </label>
                <input
                  type="file"
                  accept="image/*"
                  onChange={(e) => setImageFile(e.target.files?.[0] || null)}
                  className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                />
                <img
                  src={getFilesUrl(formData.imageUrl)}
                  alt={formData.title}
                  className="w-20 h-20 object-cover rounded-xl mt-3"
                />
              </div>

              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">
                  Start Date
                </label>
                <input
                  type="datetime-local"
                  name="startDate"
                  value={formData.startDate}
                  onChange={handleChange}
                  className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                />
              </div>

              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">
                  Category
                </label>
                <select
                  value={formData.category}
                  onChange={handleChange}
                  name="category"
                  className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                >
                  <option value="" disabled>
                    Choose category
                  </option>
                  {categoryData?.map((item, index) => (
                    <option key={index} value={item._id}>
                      {item.name}
                    </option>
                  ))}
                </select>
              </div>

              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">
                  Price ($)
                </label>
                <input
                  type="number"
                  name="price"
                  value={formData.price}
                  onChange={handleChange}
                  className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                />
              </div>

              <button
                type="submit"
                className="bg-blue-500 text-white px-4 py-2 rounded-md"
              >
                Update
              </button>
              <button
                type="button"
                onClick={handleDelete}
                className="bg-red-500 text-white px-4 py-2 rounded-md mx-3"
              >
                Delete
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditClass;
