import React from "react";

import { useTranslation } from "react-i18next";

interface InstructorProps {
  profile: {
    title: string;
    body: string;
    image: string;
    advantages?: any[] | undefined;
  };
}

const Instructor: React.FC<InstructorProps> = ({ profile }) => {
  const { t } = useTranslation();
  return (
    <section className="bg-main bg-opacity-20 py-10 sm:py-20">
      <div className="container-fluid">
        <div className="flex md:flex-row flex-col items-center justify-between gap-10">
          <div className="tutor__images w-full md:w-2/4 h-[320px] sm:h-[500px] md:h-[600px] rounded-3xl overflow-hidden">
            <img
              src={profile.image}
              alt="profile"
              className="w-full h-full object-cover"
            />
          </div>
          <div className="w-full md:w-2/4 flex flex-col gap-4">
            <h3 className="text-xl sm:text-2xl lg:text-3xl font-semibold">
              {profile.title}
            </h3>
            <p>{profile.body}</p>
            <ul className=" list-disc list-inside pt-5">
              {profile?.advantages?.map((item, index) => (
                <li key={index} className="pb-2">
                  {item}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Instructor;
