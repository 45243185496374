import React from "react";
import { useTranslation } from "react-i18next";
import {
  FaVideo,
  FaMedal,
  FaCalendarCheck,
  FaUserGraduate,
} from "react-icons/fa";

function FeaturesBar() {
  const { t } = useTranslation();

  const features = [
    {
      icon: <FaVideo className="text-orange-500 text-xl" />,
      label: t("features.liveLessons"),
    },
    {
      icon: <FaMedal className="text-yellow-500 text-xl" />,
      label: t("features.qualifiedTeachers"),
    },
    {
      icon: <FaCalendarCheck className="text-green-500 text-xl" />,
      label: t("features.flexibleSchedule"),
    },
    {
      icon: <FaUserGraduate className="text-blue-400 text-xl" />,
      label: t("features.studentAge"),
    },
  ];

  return (
    <div className="bg-gray-200 py-4 px-24">
      <div className="container mx-auto grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 justify-around items-center gap-3 lg:gap-0 md:px-0 px-5">
        {features.map((feature, index) => (
          <div key={index} className="flex items-center space-x-2">
            {feature.icon}
            <span className="text-white-800 font-medium">{feature.label}</span>
          </div>
        ))}
      </div>
    </div>
  );
}

export default FeaturesBar;
