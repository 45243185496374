import React, { useContext } from "react";
import { Context } from "../App";
import { Roles } from "../common/types/role";
import { useLocation, useNavigate } from "react-router-dom";
import { ACCESS_TOKEN } from "../common/api";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);

export default function Sidebar() {
  const { user } = useContext(Context);
  const location = useLocation();
  const navigate = useNavigate();
  const userRole = user?.role;

  // Array of navigation items
  const navItems = [
    { name: "Classes and Groups", href: "/classes", isActive: true, role: Roles[1] },
    { name: "Courses", href: "#", isActive: false, role: Roles[1] },
    // { name: "Add Group", href: "/add-group", isActive: true, role: Roles[0] },
    { name: "Add Class and Group", href: "/add-class-group", isActive: true, role: Roles[0] },
    { name: "Progress", href: "#", isActive: false, role: Roles[1] },
    { name: "Schedule", href: "#", isActive: false, role: Roles[1] },
    { name: "Account", href: "#", isActive: false, role: Roles[1] },
    { name: "Settings", href: "#", isActive: false, role: "all" },
  ];

  // Filter items based on role
  const filteredNavItems = navItems.filter(
    (item) => item.role === userRole || item?.role === "all"
  );

  const handleLogout = async () => {
    const result = await MySwal.fire({
      title: "Are you sure?",
      text: "You will be logged out!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, log out!",
      cancelButtonText: "Cancel",
    });

    if (result.isConfirmed) {
      localStorage.removeItem(ACCESS_TOKEN);
      navigate("/login");
      MySwal.fire("Logged Out", "You have successfully logged out!", "success");
    }
  };
  

  return (
    <nav>
      <ul className="space-y-4">
        {location.pathname !== "/dashboard" ? (
          <li>
            <a
              href={"/dashboard"}
              className="flex items-center gap-3 opacity-80"
            >
              <span>
                <svg
                  width="16"
                  height="10"
                  viewBox="0 0 16 10"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className=""
                >
                  <path
                    d="M5 1L1 5M1 5L5 9M1 5H15"
                    stroke="white"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </span>
              Go to dashboard
            </a>
          </li>
        ) : (
          <></>
        )}
        {/* Map over navItems array to generate list items */}
        {filteredNavItems.map((item, index) => {
          if (location.pathname !== "/dashboard") {
          }
          return (
            <li key={index}>
              <a
                href={item.href}
                className="flex items-center justify-between p-2 bg-blue-800 rounded-lg hover:bg-blue-600"
              >
                {item.name}
                {!item?.isActive ? (
                  <span>
                    <svg
                      width="16"
                      height="20"
                      viewBox="0 0 16 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="opacity-70"
                    >
                      <path
                        d="M8 0C9.32608 0 10.5979 0.526784 11.5355 1.46447C12.4732 2.40215 13 3.67392 13 5V8C13.7956 8 14.5587 8.31607 15.1213 8.87868C15.6839 9.44129 16 10.2044 16 11V17C16 17.7956 15.6839 18.5587 15.1213 19.1213C14.5587 19.6839 13.7956 20 13 20H3C2.20435 20 1.44129 19.6839 0.87868 19.1213C0.316071 18.5587 0 17.7956 0 17V11C0 10.2044 0.316071 9.44129 0.87868 8.87868C1.44129 8.31607 2.20435 8 3 8V5C3 3.67392 3.52678 2.40215 4.46447 1.46447C5.40215 0.526784 6.67392 0 8 0ZM8 12C7.49542 11.9998 7.00943 12.1904 6.63945 12.5335C6.26947 12.8766 6.04284 13.3468 6.005 13.85L6 14C6 14.3956 6.1173 14.7822 6.33706 15.1111C6.55682 15.44 6.86918 15.6964 7.23463 15.8478C7.60009 15.9991 8.00222 16.0387 8.39018 15.9616C8.77814 15.8844 9.13451 15.6939 9.41421 15.4142C9.69392 15.1345 9.8844 14.7781 9.96157 14.3902C10.0387 14.0022 9.99913 13.6001 9.84776 13.2346C9.69638 12.8692 9.44004 12.5568 9.11114 12.3371C8.78224 12.1173 8.39556 12 8 12ZM8 2C7.20435 2 6.44129 2.31607 5.87868 2.87868C5.31607 3.44129 5 4.20435 5 5V8H11V5C11 4.20435 10.6839 3.44129 10.1213 2.87868C9.55871 2.31607 8.79565 2 8 2Z"
                        fill="white"
                      />
                    </svg>
                  </span>
                ) : (
                  <></>
                )}
              </a>
            </li>
          );
        })}

        <li>
          <button
            onClick={handleLogout}
            className="flex items-center justify-between p-2 bg-red-500 rounded-lg hover:bg-red-900 w-full"
          >
            Log out
          </button>
        </li>
      </ul>
    </nav>
  );
}
