import ClassCategories from "../components/ClassCategories";
import FeaturesSection from "../components/Features";
import FeaturesBar from "../components/FeaturesBar";
import Footer from "../components/Footer";
import FreeConsultationSection from "../components/FreeConsultationSection";
import HeroSection from "../components/HeroSection";
import Navbar from "../components/Navbar";
import SubjectsSection from "../components/SubjectsSection";
import TopClassesSection from "../components/TopClasses";
import TopInstructorsSection from "../components/TopInstructors";

function HomePage() {
  // const { data } = useGeneralAPIQuery("api/course");

  return (
    <>
      <Navbar />
      <HeroSection />
      <FeaturesBar />
      <FeaturesSection />
      <SubjectsSection />
      <TopClassesSection />
      <ClassCategories />
      <TopInstructorsSection />
      <FreeConsultationSection />
      <Footer />
    </>
  );
}

export default HomePage;
