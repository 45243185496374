import React from "react";
import { useTranslation } from "react-i18next";
import ListingsPage from "../components/ListingsPage";
import Navbar from "../components/Navbar";
import ClassListingHeroSection from "../components/class-details/ClassListingHeroSection";
import Footer from "../components/Footer";
import dancingClass from "../assets/dancingClass.webp";
import adv1 from "../assets/adv-1.svg";
import adv2 from "../assets/adv-2.svg";
import adv3 from "../assets/adv-3.svg";
import adv4 from "../assets/adv-4.svg";
import About from "../components/class-details/about";
import WhyChooseUs from "../components/class-details/why-choose-us";
import Pricing from "../components/class-details/pricing";
import Testimonials from "../components/class-details/testimonials";
import Instructor from "../components/class-details/instructor";
import Advantages from "../components/class-details/advantages";
import FAQ from "../components/class-details/FAQ";
import Consultations from "../components/class-details/consultation";
import myprofile from "./../assets/myProfilePicture.png";
import profileIcon from "../assets/profile-icon.jpg";
import person from "../assets/EnglishClass.webp";

const ArtClassListings: React.FC = () => {
  const { t } = useTranslation();

  // Define artClasses and tags arrays inside the component function
  const artClasses = [
    {
      id: 1,
      title: t("artClasses.listings.title"), // Translated title
      description: t("artClasses.listings.description"), // Translated description
      ageRange: t("artClasses.listings.ageRange"), // Translated age range
      imageUrl: dancingClass,
      rating: "****",
    },
  ];

  const tags = [
    t("tagsforart1", { ns: "classes" }), // Translated tag with namespace
    t("tagsforart2", { ns: "classes" }), // Translated age range
  ];

  const advantages = [
    {
      id: 1,
      name: t("advantages1", { ns: "classes" }),
      icon: adv1,
    },
    {
      id: 2,
      name: t("advantages2", { ns: "classes" }),
      icon: adv2,
    },
    {
      id: 3,
      name: t("advantages3", { ns: "classes" }),
      icon: adv3,
    },
    {
      id: 4,
      name: t("advantages4", { ns: "classes" }),
      icon: adv4,
    },
  ];

  const plans = [
    {
      title: "starter",
      price: "Free",
      features: ["Basic support", "5 projects", "Community access"],
    },
    {
      title: "pro",
      price: "$9.99/mo",
      features: ["Priority support", "Unlimited projects", "Advanced tools"],
    },
    {
      title: "plus",
      price: "$19.99/mo",
      features: [
        "Dedicated support",
        "Unlimited projects",
        "All tools included",
      ],
    },
  ];

  const testimonials = [
    {
      name: "Marifat Shodiyeva",
      date: "8 aprel 2023 yil",
      text: "Assalomu Alaykum! 'Darstop.com' saytidan juda minnatdorman...",
      rating: 5,
      image: profileIcon,
    },
    {
      name: "Shohida Zinnatullayeva",
      date: "6 iyun 2023 yil",
      text: "Assalomu Alaykum! Ismim Shohida, Sirdaryo viloyatidanman...",
      rating: 5,
      image: profileIcon,
    },
    // Add more testimonials as needed
  ];

  const profile = {
    title: t("tutor.art.title", { ns: "classes" }),
    body: t("tutor.art.body", { ns: "classes" }),
    image: myprofile,
    advantages: t("tutor.art.advantages", {
      ns: "classes",
      returnObjects: true,
    }) as string[],
  };

  return (
    <>
      <Navbar />
      <ClassListingHeroSection
        title={t("artClasses.title")}
        description={t("artClasses.description")}
        reviews={t("artClasses.reviews")}
        tags={tags} // Updated tags array
        advantages={advantages}
        background={dancingClass}
      />
      <About />
      <WhyChooseUs
        advantagesTitle={t("advantagesArtTitle", { ns: "classes" })}
      />
      {/* <Pricing plans={plans} /> */}
      <Testimonials testimonials={testimonials} />
      <Instructor profile={profile} />
      <Advantages />
      <FAQ />
      <Consultations />
      {/* <ListingsPage classes={artClasses} /> */}
      <Footer />
    </>
  );
};

export default ArtClassListings;
