import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next"; // Import the useTranslation hook
import Offcanvas from "./modals/Offcanvas";
import logo from "../assets/logo.png";
import Button from "./custom/button";

function Navbar() {
  const [isOffcanvas, setIsOffcanvas] = useState<boolean>(false);
  const [isSubjectsDropdownOpen, setIsSubjectsDropdownOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [isClassesDropdownOpen, setIsClassesDropdownOpen] = useState(false);

  const { t, i18n } = useTranslation(); // Destructure t and i18n from useTranslation

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
    localStorage.setItem("language", lng); // Save the selected language to local storage
  };

  const toggleDropdown = () => {
    setIsSubjectsDropdownOpen(!isSubjectsDropdownOpen);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsSubjectsDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <nav className="py-4 px-4 sm:px-8 bg-white-600 text-black">
      <div className="container flex justify-between items-center mx-auto">
        <div className="text-base sm:text-2xl font-bold">
          <a href="/">
            <span className="text-orange-500">
              <img src={logo} alt="logo" className="w-32 h-auto" />
            </span>
          </a>
        </div>
        <ul className="lg:flex hidden space-x-6 text-slate-900">
          <li>
            <div ref={dropdownRef} className="relative">
              <button
                onClick={toggleDropdown}
                //setIsSubjectsDropdownOpen(!isSubjectsDropdownOpen)

                className="text-white-700 hover:text-orange-500 focus:outline-none"
              >
                {t("navbar.subjects")} <span className="ml-1">▼</span>{" "}
                {/* Use t for translation */}
              </button>

              {/* Subjects dropdown menu */}
              {isSubjectsDropdownOpen && (
                <div className="absolute mt-2 w-48 bg-white shadow-lg rounded-lg py-2 z-20">
                  <Link
                    to="/quran-class"
                    className="block px-4 py-2 text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                  >
                    {t("dropdown.quran")}
                  </Link>
                  <Link
                    to="/english-class"
                    className="block px-4 py-2 text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                  >
                    {t("dropdown.english")}
                  </Link>
                  <Link
                    to="/coding-class"
                    className="block px-4 py-2 text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                  >
                    {t("dropdown.coding")}
                  </Link>
                  <Link
                    to="/art-class"
                    className="block px-4 py-2 text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                  >
                    {t("dropdown.art")}
                  </Link>
                </div>
              )}
            </div>
          </li>

          {/* <li>
            <div className="relative">
              <div
                onClick={() => setIsClassesDropdownOpen(!isClassesDropdownOpen)}
                className="text-white-700 hover:text-orange-500 focus:outline-none"
              >
                {t("navbar.classes")} <span className="ml-1">▼</span>
              </div>

              {isClassesDropdownOpen && (
                <div className="absolute mt-2 w-48 bg-white shadow-lg rounded-lg py-2 z-20">
                  <Link
                    to="#"
                    className="block px-4 py-2 text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                  >
                    {t("dropdown.tutoring")}
                  </Link>
                  <Link
                    to="#"
                    className="block px-4 py-2 text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                  >
                    {t("dropdown.fullCourse")}
                  </Link>
                  <Link
                    to="#"
                    className="block px-4 py-2 text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                  >
                    {t("dropdown.collegePrep")}
                  </Link>
                  <Link
                    to="#"
                    className="block px-4 py-2 text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                  >
                    {t("dropdown.afterschool")}
                  </Link>
                  <Link
                    to="#"
                    className="block px-4 py-2 text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                  >
                    {t("dropdown.clubs")}
                  </Link>
                  <Link
                    to="#"
                    className="block px-4 py-2 text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                  >
                    {t("dropdown.crashCourses")}
                  </Link>
                </div>
              )}
            </div>
          </li> */}

          <li>
            <Link to="/pricing" className="hover:text-orange-500">
              {t("navbar.pricing")}
            </Link>
          </li>
          <li>
            <a href="/how-it-works" className="hover:text-orange-500">
              {t("navbar.howItWorks")}
            </a>
          </li>
          <li>
            <a href="/contact-us" className="hover:text-orange-500">
              {t("navbar.contactUs")}
            </a>
          </li>
        </ul>

        <div className="flex items-center gap-2 sm:gap-4">
          {/* Language Switcher */}
          <div className="relative">
            <button
              className={`${
                i18n.language === "en"
                  ? "text-orange-500 font-bold"
                  : "text-blue-600"
              } hover:text-orange-500`}
              onClick={() => changeLanguage("en")}
            >
              EN
            </button>
            <button
              className={`${
                i18n.language === "uz"
                  ? "text-orange-500 font-bold"
                  : "text-blue-600"
              } ml-2 hover:text-orange-500`}
              onClick={() => changeLanguage("uz")}
            >
              UZ
            </button>
          </div>

          {/* <Link
            to="/signup"
            className="bg-blue-600 text-white py-1 sm:py-2 px-2 sm:px-4 rounded-lg shadow hover:bg-blue-700"
          ></Link> */}
          <Button label="link" href="/signup">
            {t("navbar.signup")}
          </Button>
          <Button label="link" href="/login" variant="outline">
            {t("navbar.login")}
          </Button>
          {/* <Link
            to="/login"
            className="border border-blue-600 text-blue-600 py-1 sm:py-2 px-2 sm:px-4 rounded-lg hover:bg-blue-100"
          >
            {t("navbar.login")}
          </Link> */}

          <button
            type="button"
            className="lg:hidden block"
            onClick={() => setIsOffcanvas(true)}
          >
            <svg
              width="16"
              height="10"
              viewBox="0 0 16 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.8 1.66667H15.2C15.4122 1.66667 15.6157 1.57887 15.7657 1.42259C15.9157 1.26631 16 1.05435 16 0.833333C16 0.61232 15.9157 0.400358 15.7657 0.244078C15.6157 0.0877975 15.4122 0 15.2 0H0.8C0.587827 0 0.384344 0.0877975 0.234315 0.244078C0.0842854 0.400358 0 0.61232 0 0.833333C0 1.05435 0.0842854 1.26631 0.234315 1.42259C0.384344 1.57887 0.587827 1.66667 0.8 1.66667ZM15.2 8.33333H0.8C0.587827 8.33333 0.384344 8.42113 0.234315 8.57741C0.0842854 8.73369 0 8.94565 0 9.16667C0 9.38768 0.0842854 9.59964 0.234315 9.75592C0.384344 9.9122 0.587827 10 0.8 10H15.2C15.4122 10 15.6157 9.9122 15.7657 9.75592C15.9157 9.59964 16 9.38768 16 9.16667C16 8.94565 15.9157 8.73369 15.7657 8.57741C15.6157 8.42113 15.4122 8.33333 15.2 8.33333ZM15.2 4.16667H0.8C0.587827 4.16667 0.384344 4.25446 0.234315 4.41074C0.0842854 4.56702 0 4.77899 0 5C0 5.22101 0.0842854 5.43298 0.234315 5.58926C0.384344 5.74554 0.587827 5.83333 0.8 5.83333H15.2C15.4122 5.83333 15.6157 5.74554 15.7657 5.58926C15.9157 5.43298 16 5.22101 16 5C16 4.77899 15.9157 4.56702 15.7657 4.41074C15.6157 4.25446 15.4122 4.16667 15.2 4.16667Z"
                fill="#161A1D"
              />
            </svg>
          </button>
        </div>
      </div>
      <Offcanvas isOffcanvas={isOffcanvas} setIsOffcanvas={setIsOffcanvas} />
    </nav>
  );
}

export default Navbar;
