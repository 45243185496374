import React, { useContext, useEffect, useState } from "react";
import { Context } from "../App";
import Sidebar from "./Sidebar";
import {
  getEnrolledClasses,
  getOneClass,
  getOwnClasses,
} from "../common/api/path/ClassPath";
import { formatDate } from "../common/helpers/utils";
import { ACCESS_TOKEN, baseURL } from "../common/api";
import { getOneGroup, getOwnGroups } from "../common/api/path/GroupPath";

const Dashboard: React.FC = () => {
  const { user } = useContext(Context);
  const userRole = user?.role;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [enrolledClasses, setEnrolledClasses] = useState<[] | any>([]);
  const [ownTeacherClassData, setOwnTeacherClassData] = useState<[] | any>([]);
  const [ownTeacherGroupData, setOwnTeacherGroupData] = useState<[] | any>([]);
  const local = localStorage.getItem(ACCESS_TOKEN);

  useEffect(() => {
    const fetchClasses = async () => {
      setLoading(true);
      try {
        const enrolled: any = await getEnrolledClasses();

        const classDetailsPromises = enrolled.map((enrolledClass: any) => {
          if (enrolledClass.classId) {
            return getOneClass(enrolledClass.classId).then((classData) => ({
              ...classData,
              type: "class", // Add type for identification
            }));
          } else if (enrolledClass.groupId) {
            return getOneGroup(enrolledClass.groupId).then((groupData) => ({
              ...groupData,
              type: "group", // Add type for identification
            }));
          }
        });

        // const groupDetailsPromises = enrolled.map((enrolledGroups: any) => {});

        const classDetails = await Promise.all(classDetailsPromises);
        setEnrolledClasses(classDetails);
      } catch (error) {
        setError("Failed to load classes");
      } finally {
        setLoading(false);
      }
    };

    const fetchOwnClassesAndGroups = async () => {
      setLoading(true);
      try {
        const dataClasses: any = await getOwnClasses();
        const dataGroups: any = await getOwnGroups();

        setOwnTeacherClassData(dataClasses);
        setOwnTeacherGroupData(dataGroups);
      } catch (error) {
        setError("Failed to load classes");
      } finally {
        setLoading(false);
      }
    };

    // Only fetch classes for a student role
    if (userRole === "student") {
      fetchClasses();
    } else if (userRole === "teacher") {
      fetchOwnClassesAndGroups();
    }
  }, [userRole, user, local]);

  return (
    <div className="min-h-screen bg-gray-100">
      {/* Sidebar */}
      <div className="flex">
        <div className="w-64 bg-blue-700 min-h-screen text-white p-6">
          <h2 className="text-2xl font-semibold mb-8">Dashboard</h2>
          <Sidebar />
        </div>

        {/* Main Content */}
        <div className="flex-1 p-6">
          <h1 className="text-3xl font-bold mb-6">
            Welcome Back, {userRole} !
          </h1>

          {/* Main Dashboard Cards */}
          <div className="bg-white p-6 rounded-lg shadow-md">
            <h3 className="text-lg font-semibold mb-4">My Classes</h3>
            {/* Acting the Scene Card */}
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-3">
              {enrolledClasses ? (
                enrolledClasses.map((item: any, index: number) => {
                  return (
                    <div
                      key={index}
                      className="bg-purple-100 p-4 rounded-lg shadow-md flex items-start"
                    >
                      <a href={`/classroom/${item._id}/${item.type}`}>
                        <img
                          src={baseURL + "/files/" + item.imageUrl}
                          alt="Acting the Scene"
                          className="w-20 h-20 rounded-lg object-cover mr-4"
                        />
                        <div>
                          <h4 className="font-bold text-purple-600 mb-1">
                            {item.title}
                          </h4>
                          <p className="text-sm text-gray-600">
                            {formatDate(item.startDate)}
                          </p>
                        </div>
                      </a>
                    </div>
                  );
                })
              ) : (
                <p className=" text-gray-500">
                  You haven't manual purchased class or groups.
                </p>
              )}
              {ownTeacherClassData.map((item: any, index: number) => {
                return (
                  <div
                    key={index}
                    className="bg-purple-100 p-4 rounded-lg shadow-md flex items-start"
                  >
                    <a href={`/edit/${item._id}/class`}>
                      <img
                        src={baseURL + "/files/" + item.imageUrl}
                        alt="Acting the Scene"
                        className="w-20 h-20 rounded-lg object-cover mr-4"
                      />
                      <div>
                        <h4 className="font-bold text-purple-600 mb-1">
                          {item.title}
                        </h4>
                        <p className="text-sm text-gray-600">
                          <span className="text-red-500">Class</span> /{" "}
                          {formatDate(item.startDate)}
                        </p>
                      </div>
                    </a>
                  </div>
                );
              })}
              {ownTeacherGroupData.map((item: any, index: number) => {
                return (
                  <div
                    key={index}
                    className="bg-purple-100 p-4 rounded-lg shadow-md flex items-start"
                  >
                    <a href={`/edit/${item._id}/group`}>
                      <img
                        src={baseURL + "/files/" + item.imageUrl}
                        alt="Acting the Scene"
                        className="w-20 h-20 rounded-lg object-cover mr-4"
                      />
                      <div>
                        <h4 className="font-bold text-purple-600 mb-1">
                          {item.title}
                        </h4>
                        <p className="text-sm text-gray-600">
                          <span className="text-red-500">Group</span> /{" "}
                          {formatDate(item.startDate)}
                        </p>
                      </div>
                    </a>
                  </div>
                );
              })}
            </div>
          </div>

          {/* <div className="bg-white p-6 rounded-lg shadow-md">
              <h3 className="text-lg font-semibold mb-4">Recent Progress</h3>
              <p>You have completed 3 out of 5 modules in your Math course.</p>
            </div>

            <div className="bg-white p-6 rounded-lg shadow-md">
              <h3 className="text-lg font-semibold mb-4">Messages</h3>
              <p>You have 2 new messages from your instructor.</p>
            </div> */}
          {/* Additional Widgets */}
          {/* <div className="mt-8 grid grid-cols-1 md:grid-cols-2 gap-6">
            <div className="bg-white p-6 rounded-lg shadow-md">
              <h3 className="text-lg font-semibold mb-4">Your Schedule</h3>
              <ul>
                <li>Monday: 2:00 PM - Math Class</li>
                <li>Tuesday: 4:00 PM - English Class</li>
                <li>Thursday: 3:00 PM - Coding Class</li>
              </ul>
            </div>

            <div className="bg-white p-6 rounded-lg shadow-md">
              <h3 className="text-lg font-semibold mb-4">Achievements</h3>
              <p>
                You've unlocked 2 new badges for your Math and Coding courses.
              </p>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
