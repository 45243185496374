import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import bg from "./../../assets/about.jpg";
import Button from "../custom/button";
import adv1 from "./../../assets/video.png";
import adv2 from "./../../assets/tick.svg";
import adv3 from "./../../assets/pen-tool.svg";
import adv4 from "./../../assets/award.svg";

const About = () => {
  const { t, i18n } = useTranslation();

  return (
    <div className="py-10 sm:py-20">
      <div className="container-fluid grid grid-cols-1 lg:grid-cols-2 gap-5 w-full">
        <h4 className="text-2xl md:text-3xl lg:text-4xl font-semibold col-span-1 lg:col-span-2 pb-5 sm:pb-7">
          {t("classAboutTitle", { ns: "classes" })}
        </h4>
        {/* 1 */}
        <div className="flex flex-col gap-5">
          <div className="flex flex-col gap-5 bg-gray-100 bg-opacity-70 rounded-xl p-7 sm:p-10">
            <div className="flex items-center gap-5">
              <img
                src={adv1}
                alt="video play"
                className="w-10 sm:w-20 h-10 sm:h-20"
              />
              <h6 className="text-lg sm:text-xl font-semibold">
                {t("aboutAdvTitle1", { ns: "classes" })}
              </h6>
            </div>
            <p
              className="opacity-80"
              dangerouslySetInnerHTML={{
                __html: t("aboutAdvDesc1", { ns: "classes" }),
              }}
            ></p>
          </div>
          <div className="flex flex-col gap-5 bg-gray-100 bg-opacity-70 rounded-xl p-7 sm:p-10">
            <div className="flex items-center gap-5">
              <img
                src={adv2}
                alt="video play"
                className="w-10 sm:w-20 h-10 sm:h-20"
              />
              <h6 className="text-lg sm:text-xl font-semibold">
                {t("aboutAdvTitle2", { ns: "classes" })}
              </h6>
            </div>
            <p
              className="opacity-80"
              dangerouslySetInnerHTML={{
                __html: t("aboutAdvDesc2", { ns: "classes" }),
              }}
            ></p>
          </div>
        </div>
        {/* 2 */}
        <div className="flex flex-col gap-5">
          <div className="flex flex-col gap-5 bg-gray-100 bg-opacity-70 rounded-xl p-7 sm:p-10">
            <div className="flex items-center gap-5">
              <img
                src={adv3}
                alt="video play"
                className="w-10 sm:w-20 h-10 sm:h-20"
              />
              <h6 className="text-lg sm:text-xl font-semibold">
                {t("aboutAdvTitle3", { ns: "classes" })}
              </h6>
            </div>
            <p
              className="opacity-80"
              dangerouslySetInnerHTML={{
                __html: t("aboutAdvDesc3", { ns: "classes" }),
              }}
            ></p>
          </div>
          <div className="flex flex-col gap-5 bg-gray-100 bg-opacity-70 rounded-xl p-7 sm:p-10">
            <div className="flex items-center gap-5">
              <img
                src={adv4}
                alt="video play"
                className="w-10 sm:w-20 h-10 sm:h-20"
              />
              <h6 className="text-lg sm:text-xl font-semibold">
                {t("aboutAdvTitle4", { ns: "classes" })}
              </h6>
            </div>
            <p
              className="opacity-80"
              dangerouslySetInnerHTML={{
                __html: t("aboutAdvDesc4", { ns: "classes" }),
              }}
            ></p>
          </div>
        </div>

        <div className="w-full flex items-center justify-center col-span-1 lg:col-span-2">
          <a
            href="https://scheduler.zoom.us/darstop/free-trial-lesson"
            target="_blank"
          >
            <Button
              label={t("banner.freeButton")}
              variant="primary"
              size="large"
            ></Button>
          </a>
        </div>
      </div>
    </div>
    // <div className="py-10 sm:py-20">
    //   <div className="container-fluid flex sm:flex-row flex-col gap-5 sm:gap-10 justify-between items-start relative z-0">
    //     <div className="flex flex-col gap-5 w-full sm:w-3/4 lg:w-2/4 bg-gray-100 p-10 items-start sm:min-h-[500px] mt-10 lg:ml-20 rounded-3xl">
    //       <h1 className="text-2xl md:text-3xl lg:text-4xl font-semibold">
    //         {t("classAboutTitle", { ns: "classes" })}
    //       </h1>
    //       <div className="w-10 h-1 bg-main rounded-sm"></div>
    //       <p className="opacity-80 pb-10">
    //         {t("classAboutDesc", { ns: "classes" })}
    //       </p>
    //       <Button
    //         label={t("button.enroll_now")}
    //         variant="primary"
    //         size="large"
    //       />
    //     </div>
    //     <div className="sm:w-[500px] w-full h-[320px] sm:h-[580px] overflow-hidden sm:absolute z-[-1] top-0 right-0 rounded-3xl">
    //       <img src={bg} alt="bg" className="w-full h-full object-cover" />
    //     </div>
    //   </div>
    // </div>
  );
};

export default About;
