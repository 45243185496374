import React from "react";
import { useTranslation } from "react-i18next";
import tutoring from "../assets/tutoring.webp";
import collegePrep from "../assets/collegePrep.webp";
import afterschool from "../assets/afterschool.webp";
import fullCourses from "../assets/fullCourses.webp";
import shortCourses from "../assets/shortCourses.webp";
import clubs from "../assets/clubs.webp";

interface Category {
  title: string;
  imageUrl: string;
}

const categories: Category[] = [
  {
    title: "classCategories.tutoring", // Key for translation
    imageUrl: tutoring,
  },
  {
    title: "classCategories.fullCourses", // Key for translation
    imageUrl: fullCourses,
  },
  {
    title: "classCategories.collegePrep", // Key for translation
    imageUrl: collegePrep,
  },
  {
    title: "classCategories.afterschool", // Key for translation
    imageUrl: afterschool,
  },
  {
    title: "classCategories.clubs", // Key for translation
    imageUrl: clubs,
  },
  {
    title: "classCategories.shortCourses", // Key for translation
    imageUrl: shortCourses,
  },
];

const ClassCategories: React.FC = () => {
  const { t } = useTranslation(); // Use translation hook

  return (
    <div className="py-10 bg-gray-200">
      <div className="container mx-auto md:px-0 px-5">
        <h2 className="text-2xl sm:text-3xl font-bold text-center mb-8" data-aos="fade-up">
          {t("classCategories.title")} {/* Translated title */}
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {categories.map((category, index) => (
            <div
              key={index}
              data-aos="fade-up"
              className="bg-white rounded-lg shadow-md overflow-hidden"
            >
              <div className="flex justify-center items-center pt-12">
                <img
                  src={category.imageUrl}
                  alt={t(category.title)} // Translated title for alt text
                  className="sm:h-48 sm:w-48 h-20 w-20"
                />
              </div>
              <div className="p-4 text-center">
                <h3 className="text-lg font-semibold">{t(category.title)}</h3>{" "}
                {/* Translated category title */}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ClassCategories;
