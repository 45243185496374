import React, { useState } from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import axiosInstance from "../common/api/axios";
import { useTranslation } from "react-i18next";

interface ContactDetailsModalProps {
  closeModal: () => void;
}

const MySwal = withReactContent(Swal);

const ContactDetailsModal: React.FC<ContactDetailsModalProps> = ({
  closeModal,
}) => {
  const [email, setEmail] = useState<string>("");
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const { t } = useTranslation();

  const handleConfirm = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!email || !phoneNumber || !name) {
      MySwal.fire({
        icon: "error",
        title: t("form.inputError", { ns: "classes" }),
        text: t("form.fillAllFields", { ns: "classes" }),
      });
      return;
    }

    setLoading(true);

    try {
      const response = await axiosInstance.post("/mail", {
        name,
        email,
        phoneNumber,
        message: "From Contact Modal",
      });

      if (response.status === 201) {
        MySwal.fire({
          icon: "success",
          title: t("form.successTitle"),
          // text: t("form.successText"),
        });
        closeModal();
      } else {
        throw new Error("Failed to submit contact details.");
      }
    } catch (error) {
      MySwal.fire({
        icon: "error",
        title: t("form.errorTitle"),
        // text: t("form.errorText", { ns: "classes" }),
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div
      className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50"
      onClick={() => closeModal()}
    >
      <div
        className="flex justify-center items-center max-w-lg bg-purple-100 rounded-2xl"
        onClick={(e) => e.stopPropagation()}
      >
        <form
          onSubmit={handleConfirm}
          className="bg-purple-200 p-8 rounded-lg shadow-lg w-full max-w-md"
        >
          <h2 className="text-2xl font-bold mb-4 text-center text-gray-800">
            {t("form.title", { ns: "classes" })}
          </h2>
          <p className="text-center text-gray-600 mb-6">
            {t("form.body", { ns: "classes" })}
          </p>

          <input
            type="text"
            name="name"
            placeholder={t("form.namePlaceholder")}
            value={name}
            required
            onChange={(e) => setName(e.target.value)}
            className="w-full p-3 mb-4 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-purple-400"
          />

          <input
            type="email"
            name="email"
            placeholder={t("form.emailPlaceholder")}
            value={email}
            required
            onChange={(e) => setEmail(e.target.value)}
            className="w-full p-3 mb-4 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-purple-400"
          />

          <div className="relative mb-4">
            <input
              type="tel"
              name="phone"
              placeholder={t("form.phonePlaceholder")}
              value={phoneNumber}
              required
              onChange={(e) => setPhoneNumber(e.target.value)}
              className="w-full p-3 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-purple-400"
            />
          </div>

          <button
            type="submit"
            disabled={loading}
            className={`w-full bg-purple-500 text-white p-3 rounded-lg font-semibold hover:bg-purple-600 transition duration-300 ${
              loading ? "cursor-not-allowed opacity-50" : ""
            }`}
          >
            {loading
              ? t("form.loading", { ns: "classes" })
              : t("form.signUp", { ns: "classes" })}
          </button>

          <p className="text-center text-gray-600 mt-4 text-sm">
            {t("form.offer", { ns: "classes" })}
          </p>
        </form>
      </div>
    </div>
  );
};

export default ContactDetailsModal;
