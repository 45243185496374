import React, { useEffect, useState } from "react";
import defaultProfile from "../assets/defaultProfile.webp";
import { useNavigate, useParams } from "react-router-dom";
import {
  ClassData,
  getOneClass,
  getPublicClasses,
} from "../common/api/path/ClassPath";
import { formatDate } from "../common/helpers/utils";
import { baseURL } from "../common/api";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { getOneGroup } from "../common/api/path/GroupPath";

const MySwal = withReactContent(Swal);

interface Classmate {
  name: string;
  avatarUrl: string;
  role: string;
}

const classmates: Classmate[] = [
  { name: "Odil", avatarUrl: "https://example.com/avatar1.jpg", role: "Me" },
  { name: "Zoey", avatarUrl: "https://example.com/avatar2.jpg", role: "" },
  { name: "Hawwaa", avatarUrl: "https://example.com/avatar3.jpg", role: "" },
];

const Classroom: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  // it return type= class or group
  const { type } = useParams<{ type: string }>();
  const [classInfo, setClass] = useState<ClassData | any>({});
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();

  // Fetch classes data from the API
  useEffect(() => {
    const fetchClasses = async () => {
      try {
        let data;
        if(type == "class"){
          data = await getOneClass(id); // Using the service to fetch data
        }else{
          data = await getOneGroup(id);
        }
        setClass(data);
        setLoading(false);
      } catch (err) {
        console.error(err);
        setError("Failed to load class.");
        setLoading(false);
      }
    };

    fetchClasses();
  }, []);

  console.log(classInfo);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  const handleClick = async () => {
    const result = await MySwal.fire({
      title: "Are you sure?",
      text: "Do you want to join in this class?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, join me!",
    });

    if (result.isConfirmed) {
      window.location.href = classInfo.link;
    }
  };

  return (
    <div className="min-h-screen bg-gray-100">
      <div className="container mx-auto p-6">
        {/* Header */}
        <h1 className="text-3xl font-bold mb-6">{classInfo.title}</h1>
        <p className="text-lg text-gray-600 mb-4">
          {formatDate(classInfo.startDate)}
        </p>

        {/* Join Live Meeting Button */}
        <button
          onClick={handleClick}
          className="bg-purple-600 text-white py-2 px-4 rounded-lg hover:bg-purple-700 transition"
        >
          Join Live Meeting
        </button>

        <div className="mt-10 w-full">
          <img
            src={baseURL + "/files/" + classInfo.imageUrl}
            alt="Acting the Scene"
            className="w-full h-full rounded-lg object-cover mr-4"
          />
        </div>

        {/* <div className="mt-8 grid grid-cols-1 md:grid-cols-3 gap-8">
          <div className="md:col-span-2">
            <div className="bg-white shadow-lg rounded-lg p-6 mb-8">
              <h2 className="text-xl font-semibold text-purple-600 mb-4">
                Hi Odil!
              </h2>
              <p>
                This page is for class announcements and discussions. Please
                refresh the page to see the latest updates.
              </p>

              <div className="bg-purple-100 p-4 rounded-lg mt-6">
                <h3 className="text-lg font-bold">Welcome Post</h3>
                <p className="text-sm text-gray-600 mt-2">
                  Posted on Sunday, Jan 23, 2022 at 4:39 PM
                </p>
                <p className="mt-4">
                  Welcome! I’m excited that you joined this class. Please
                  introduce yourself to me and the other learners...
                </p>

                <div className="mt-4 space-y-4">
                  <div className="bg-gray-100 p-2 rounded-lg">
                    <p className="text-sm">
                      <strong>Sri</strong> So good to have you in class again!
                      Welcome back 😊
                    </p>
                  </div>
                  <div className="bg-gray-100 p-2 rounded-lg">
                    <p className="text-sm">
                      <strong>Logan</strong> I like to play Minecraft & watch
                      plays
                    </p>
                  </div>
                </div>

                <div className="mt-4">
                  <input
                    type="text"
                    className="w-full p-2 border border-gray-300 rounded-lg"
                    placeholder="Write a comment as Odil"
                  />
                  <button className="mt-2 bg-purple-600 text-white py-2 px-4 rounded-lg hover:bg-purple-700 transition">
                    Send
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="bg-white shadow-lg rounded-lg p-6">
            <div className="flex items-center mb-6">
              <img
                src={defaultProfile}
                alt="Odil"
                className="w-16 h-16 rounded-full object-cover mr-4"
              />
              <div>
                <h3 className="font-bold text-xl">Odil</h3>
                <button className="text-blue-600 text-sm mt-2">
                  Edit your profile
                </button>
              </div>
            </div>

            <h3 className="font-semibold text-lg mb-4">Classmates (3)</h3>
            <ul className="space-y-4">
              {classmates.map((classmate, index) => (
                <li key={index} className="flex items-center">
                  <img
                    src={classmate.avatarUrl}
                    alt={classmate.name}
                    className="w-10 h-10 rounded-full object-cover mr-4"
                  />
                  <div>
                    <p className="font-semibold">{classmate.name}</p>
                    {classmate.role && (
                      <p className="text-sm text-gray-500">{classmate.role}</p>
                    )}
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default Classroom;
