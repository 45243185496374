import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import axiosInstance from "../common/api/axios";
import WhatsAppIcon from "../assets/whatsapp.png";
import TelegramIcon from "../assets/Telegram-logo.png";
import ChatIcon from "../assets/Telegram-logo.png";

const FreeConsultationSection: React.FC = () => {
  const { t } = useTranslation();

  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setMessage(""); // Clear previous messages
    setError(""); // Clear previous errors

    try {
      const response = await axiosInstance.post("/mail", {
        name,
        phoneNumber: phone,
        email,
        message: "",
      });
      if (response.status === 201) {
        setMessage(t("form.successMessage"));
        setName("");
        setPhone("");
        setEmail("");
      } else {
        setError(t("form.errorMessage"));
      }
    } catch (err) {
      setError(t("form.errorMessage"));
    }
  };

  return (
    <>
      <div className="bg-gray-200 text-white py-10">
        <div className="container mx-auto px-6">
          <h2 className="text-2xl sm:text-3xl font-bold text-center mb-6 text-gray-900">
            {t("form.title")}
          </h2>

          <form
            onSubmit={handleSubmit}
            className="flex flex-col md:flex-row justify-center items-center space-y-4 md:space-y-0 md:space-x-4"
          >
            <input
              type="text"
              placeholder={t("form.namePlaceholder")}
              className="px-4 py-2 rounded-lg text-black w-full md:w-auto"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
            <input
              type="text"
              name="phone"
              placeholder={t("form.phonePlaceholder")}
              className="px-4 py-2 rounded-lg text-black w-full md:w-auto"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              required
            />
            <input
              type="email"
              placeholder={t("form.emailPlaceholder")}
              className="px-4 py-2 rounded-lg text-black w-full md:w-auto"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <button
              type="submit"
              className="bg-purple-600 text-white py-3 px-6 rounded-lg font-bold hover:bg-purple-500"
            >
              {t("form.submitButton")}
            </button>
          </form>

          {message && (
            <p className="mt-4 text-green-500 text-center">{message}</p>
          )}

          {error && <p className="mt-4 text-red-500 text-center">{error}</p>}

          <p className="text-sm text-center mt-4 text-gray-900">
            {t("form.privacyPolicy")}
          </p>
        </div>
      </div>

      <div className="py-4 bg-gray-200">
        <div className="text-center">
          <h2 className="text-3xl font-bold text-gray-800">
            We answer any questions <span className="text-green-600">24/7</span>
          </h2>
        </div>

        <div className="mt-10 flex justify-center space-x-8">
          <div className="text-center">
            <div className="rounded-full bg-purple-100 p-4">
              <a
                href="https://wa.me/12673375224"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={WhatsAppIcon} alt="WhatsApp" className="w-14 h-14" />
              </a>
            </div>
            <h3 className="mt-4 text-lg font-medium text-gray-700">WhatsApp</h3>
          </div>

          <div className="text-center">
            <div className="rounded-full bg-blue-100 p-4">
              <a
                href="https://t.me/Shukhrat_Fayziev"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={TelegramIcon} alt="Telegram" className="w-14 h-14" />
              </a>
            </div>
            <h3 className="mt-4 text-lg font-medium text-gray-700">Telegram</h3>
          </div>

        </div>
      </div>
    </>
  );
};

{/* Online Chat */}
{/* <div className="text-center">
  <div className="rounded-full bg-green-100 p-4">
    <img src={ChatIcon} alt="Online Chat" className="w-16 h-16" />
  </div>
  <h3 className="mt-4 text-lg font-medium text-gray-700">
    Online Chat
  </h3>
</div> */}
export default FreeConsultationSection;
