import React, { useState } from "react";

interface FreeTrialModalProps {
  closeModal: () => void;
  openContactModal: () => void;
}

const FreeTrialModal: React.FC<FreeTrialModalProps> = ({
  closeModal,
  openContactModal,
}) => {
  const [selectedDate, setSelectedDate] = useState<string>("");
  const [selectedTime, setSelectedTime] = useState<string>("");
  const [selectedProgram, setSelectedProgram] = useState<string>("");
  const [selectedGrade, setSelectedGrade] = useState<string>("");

  const handleDateChange = (date: string) => setSelectedDate(date);
  const handleTimeChange = (time: string) => setSelectedTime(time);
  const handleProgramChange = (program: string) => setSelectedProgram(program);
  const handleGradeChange = (grade: string) => setSelectedGrade(grade);

  const handleConfirm = () => {
    if (selectedDate && selectedTime && selectedProgram && selectedGrade) {
      openContactModal(); // Open contact modal after booking
    } else {
      alert("Please select all 4 parameters!");
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-gray-100 p-8 rounded-lg w-full max-w-lg">
        <h2 className="text-2xl font-bold mb-4 text-gray-900">
          Bepul sinov darsga yoziling
        </h2>

        {/* Date Selection */}
        <h3 className="mb-2 font-semibold text-gray-900">San'ani tanlang!</h3>
        <div className="flex justify-between text-gray-900">
          {["03 SEP", "04 SEP", "05 SEP"].map((date) => (
            <button
              key={date}
              className={`px-4 py-2 border ${
                selectedDate === date ? "bg-blue-500 text-white" : "bg-gray-100"
              }`}
              onClick={() => handleDateChange(date)}
            >
              {date}
            </button>
          ))}
        </div>

        {/* Time Selection */}
        <h3 className="mt-4 mb-2 font-semibold text-gray-900">
          Vaqtni tanlang!
        </h3>
        <div className="grid grid-cols-3 gap-2 text-gray-900">
          {["01:00 PM", "02:00 PM", "03:00 PM", "04:00 PM", "05:00 PM"].map(
            (time) => (
              <button
                key={time}
                className={`px-4 py-2 border ${
                  selectedTime === time
                    ? "bg-blue-500 text-white"
                    : "bg-gray-100"
                }`}
                onClick={() => handleTimeChange(time)}
              >
                {time}
              </button>
            )
          )}
        </div>
        <div className="mt-8">
          <h2 className="text-lg font-semibold mb-2 text-gray-900">
            Fanni tanlang!
          </h2>
          <div className="flex justify-around text-gray-900">
            {["Coding", "Math", "English", "Art"].map((grade) => (
              <button
                key={grade}
                onClick={() => handleGradeChange(grade)}
                className={`px-4 py-2 border ${
                  selectedGrade === grade
                    ? "bg-blue-500 text-white"
                    : "bg-gray-100"
                }`}
              >
                {grade}
              </button>
            ))}
          </div>
        </div>
        <div className="mt-8">
          <h2 className="text-lg font-semibold mb-2 text-gray-900">
            Sinfni tanlang!
          </h2>
          <div className="flex justify-around text-gray-900">
            {["1-sinf", "2-sinf", "3-sinf", "4-sinf"].map((program) => (
              <button
                key={program}
                onClick={() => handleProgramChange(program)}
                className={`px-4 py-2 border ${
                  selectedProgram === program
                    ? "bg-blue-500 text-white"
                    : "bg-gray-100"
                }`}
              >
                {program}
              </button>
            ))}
          </div>
        </div>

        {/* Confirm Button */}
        <div className="mt-4 flex justify-end space-x-4">
          <button
            onClick={closeModal}
            className="px-4 py-2 border rounded-lg text-red-400"
          >
            Cancel
          </button>
          <button
            onClick={handleConfirm}
            className="px-4 py-2 bg-blue-500 text-white rounded-lg"
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default FreeTrialModal;
