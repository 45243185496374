import axiosInstance from "../axios";

// Define the ClassData interface
export interface ClassData {
  _id?: string;
  title: string;
  description: string;
  imageUrl: string;
  startDate?: any;
  category?: string;
  price?: any;
  teacherId?: any;
  link?: string;
  isPublished?: string;
  isActive?: string;
}

// Define the EnrolledClass interface
export interface EnrolledClass {
  _id: string;
  classId: string;
  studentId: string;
  isConfirmed: boolean;
}

// Get enrolled classes
export const getEnrolledClasses = async (): Promise<ClassData> => {
  const response = await axiosInstance.get<ClassData>("/manual-purchase"); // Typing the response
  return response.data;
};

// Get own classes for teacher
export const getOwnClasses = async (): Promise<ClassData> => {
  const response = await axiosInstance.get<ClassData>("/class/own-classes"); // Typing the response
  return response.data;
};

// Get public classes
export const getPublicClasses = async (): Promise<ClassData[]> => {
  const response = await axiosInstance.get<ClassData[]>("/class/public"); // Typing the response
  return response.data;
};

// Get one public class
export const getOneClass = async (id: any): Promise<ClassData> => {
  const response = await axiosInstance.get<ClassData>(`/class/${id}`); // Typing the response
  return response.data;
};

// Create a new class
export const createClass = async (newClass: ClassData): Promise<ClassData> => {
  const response = await axiosInstance.post<ClassData>("/class", newClass); // Typing the response
  return response.data;
};

// Update an existing class
export const updateClass = async (id: any, updatedClass: ClassData): Promise<ClassData> => {
  const response = await axiosInstance.patch<ClassData>(`/class/${id}`, updatedClass); // Typing the response
  return response.data;
};

// Delete a class
export const deleteClass = async (id: any): Promise<void> => {
  await axiosInstance.delete(`/class/${id}`);
};
