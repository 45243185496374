import ContactForm from "../components/ContactForm";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";

const ContactUs: React.FC = () => {
  return (
    <>
      <Navbar />
      <ContactForm />
      <Footer />
    </>
  );
};

export default ContactUs;
