import React from "react";
import { Link } from "react-router-dom";

interface ButtonProps {
  label: string;
  onClick?: () => void;
  type?: "button" | "submit" | "reset";
  variant?: "primary" | "secondary" | "outline" | "danger";
  size?: "small" | "medium" | "large";
  disabled?: boolean;
  href?: string;
  children?: any;
  className?: string; // For additional custom styling
}

const Button: React.FC<ButtonProps> = ({
  label,
  onClick,
  type = "button",
  variant = "primary",
  size = "medium",
  disabled = false,
  href = "",
  className = "",
  children = "",
}) => {
  // Define base styles for the button
  const baseStyles =
    "rounded focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-full font-semibold";

  // Define variant styles
  const variantStyles = {
    primary: "bg-main text-white hover:bg-slate-900",
    secondary: "bg-gray-500 text-white hover:bg-gray-600",
    outline:
      "bg-transparent text-blue-500 border border-blue-500 hover:bg-blue-100",
    danger: "bg-red-500 text-white hover:bg-red-600",
  };

  // Define size styles
  const sizeStyles = {
    small: "px-3 py-1 text-sm",
    medium: "px-4 py-2 text-base",
    large: "px-5 py-3 text-lg",
  };

  // Combine all styles based on props
  const buttonStyles = `
    ${baseStyles} 
    ${variantStyles[variant]} 
    ${sizeStyles[size]} 
    ${disabled ? "opacity-50 cursor-not-allowed" : "cursor-pointer"} 
    ${className}
  `;

  if (label == "link") {
    return (
      <Link to={href}>
        <button
          type={type}
          onClick={onClick}
          className={buttonStyles}
          disabled={disabled}
        >
          {children}
        </button>
      </Link>
    );
  }

  return (
    <button
      type={type}
      onClick={onClick}
      className={buttonStyles}
      disabled={disabled}
    >
      {label}
    </button>
  );
};

export default Button;
