import React, { useContext, useEffect, useState } from "react";
import Sidebar from "../Sidebar";
import { createClass, ClassData } from "../../common/api/path/ClassPath"; // Import createGroup function
import { createGroup } from "../../common/api/path/GroupPath";
import { Category, getCategories } from "../../common/api/path/CategoryPath";
import { Context } from "../../App";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../../common/api/axios";

const MySwal = withReactContent(Swal);

interface UploadResponse {
  src: string;
}

const AddClass: React.FC = () => {
  const [categoryData, setCategoryData] = useState<Category[] | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const { user } = useContext(Context);
  const navigate = useNavigate();
  const [imageFile, setImageFile] = useState<File | null>(null);
  const [formData, setFormData] = useState<ClassData>({
    title: "",
    description: "",
    imageUrl: "",
    startDate: "",
    category: "",
    link: "",
    price: 0,
    isPublished: "true",
  });

  const [selectedType, setSelectedType] = useState<"class" | "group">("class");

  useEffect(() => {
    const fetchCategoryDetails = async () => {
      try {
        const response = await getCategories();
        setCategoryData(response);
        setLoading(false);
      } catch (err) {
        console.error(err);
        setError("Failed to load category details.");
        setLoading(false);
      }
    };

    fetchCategoryDetails();
  }, []);

  const handleChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    const { name, value } = e.target;
    const newValue = name === "price" ? parseFloat(value) : value;
    setFormData((prevData) => ({
      ...prevData,
      [name]: newValue,
    }));
  };

  const handleImageUpload = async (file: File): Promise<string> => {
    const formData = new FormData();
    formData.append("name", "file");
    formData.append("file", file);

    try {
      const response = await axiosInstance.post("/files", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      const uploadResponse = response.data as UploadResponse; // Type assertion
      return uploadResponse.src;
    } catch (error) {
      console.error("Image upload failed:", error);
      throw new Error("Failed to upload image");
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      let imageUrl = "";

      // If an image is selected, upload it first
      if (imageFile) {
        imageUrl = await handleImageUpload(imageFile);
      }

      const new_class_or_group = {
        ...formData,
        imageUrl,
        teacherId: user?._id,
      };

      // Check if it's a class or group and send the respective request
      if (selectedType === "class") {
        const createdClass = await createClass(new_class_or_group);
        console.log("Class created successfully:", createdClass);
        MySwal.fire(
          "Successfully Created",
          "You have successfully created a class!",
          "success"
        );
      } else {
        const createdGroup = await createGroup(new_class_or_group);
        console.log("Group created successfully:", createdGroup);
        MySwal.fire(
          "Successfully Created",
          "You have successfully created a group!",
          "success"
        );
      }

      navigate("/dashboard");
    } catch (error: any) {
      console.error("Error creating class or group:", error);
      setError(error.response?.data?.message || "Something went wrong");
    }
  };

  return (
    <div className="min-h-screen bg-gray-100">
      {/* Sidebar */}
      <div className="flex">
        <div className="w-64 bg-blue-700 min-h-screen text-white p-6">
          <h2 className="text-2xl font-semibold mb-8">Classes</h2>
          <Sidebar />
        </div>

        {/* Main Content */}
        <div className="flex-1 p-6">
          <h1 className="text-3xl font-bold mb-6">Welcome Back, Teacher!</h1>

          {/* Form for Adding Class */}
          <div className="bg-white p-6 rounded-lg shadow-md w-full">
            <h3 className="text-lg font-semibold mb-4">Add Class or Group</h3>
            {error ? (
              <p className="p-3 bg-red-400 rounded-lg mb-3">{error}</p>
            ) : null}

            <form onSubmit={handleSubmit}>
              {/* Radio Buttons for Class/Group Selection */}
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Select Type
                </label>
                <div className="flex items-center space-x-4">
                  <label className="inline-flex items-center">
                    <input
                      type="radio"
                      name="type"
                      value="class"
                      checked={selectedType === "class"}
                      onChange={() => setSelectedType("class")}
                      className="form-radio"
                    />
                    <span className="ml-2">Class</span>
                  </label>
                  <label className="inline-flex items-center">
                    <input
                      type="radio"
                      name="type"
                      value="group"
                      checked={selectedType === "group"}
                      onChange={() => setSelectedType("group")}
                      className="form-radio"
                    />
                    <span className="ml-2">Group</span>
                  </label>
                </div>
              </div>

              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">
                  Title
                </label>
                <input
                  type="text"
                  name="title"
                  value={formData.title}
                  onChange={handleChange}
                  className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                  required
                />
              </div>

              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">
                  Description
                </label>
                <textarea
                  name="description"
                  value={formData.description}
                  onChange={handleChange}
                  className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                  required
                ></textarea>
              </div>

              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">
                  Link
                </label>
                <input
                  type="text"
                  name="link"
                  value={formData.link}
                  onChange={handleChange}
                  className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                  required
                />
              </div>

              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">
                  Image File
                </label>
                <input
                  type="file"
                  accept="image/*"
                  onChange={(e) => setImageFile(e.target.files?.[0] || null)}
                  className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                  required
                />
              </div>

              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">
                  Start Date
                </label>
                <input
                  type="datetime-local"
                  name="startDate"
                  value={formData.startDate}
                  onChange={handleChange}
                  className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                />
              </div>

              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">
                  Category
                </label>
                <select
                  value={formData.category}
                  onChange={handleChange}
                  name="category"
                  className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                >
                  <option value="" disabled>
                    Choose category
                  </option>
                  {categoryData?.map((item, index) => (
                    <option key={index} value={item._id}>
                      {item.name}
                    </option>
                  ))}
                </select>
              </div>

              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">
                  Price ($)
                </label>
                <input
                  type="number"
                  name="price"
                  value={formData.price}
                  onChange={handleChange}
                  className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                />
              </div>

              <button
                type="submit"
                className="bg-blue-500 text-white px-4 py-2 rounded-md"
              >
                Add
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddClass;
