import axiosInstance from "../axios";

// Define the Category interface
export interface Category {
  _id: string;
  name: string;
}

// Get categories
export const getCategories = async (): Promise<Category[]> => {
  const response = await axiosInstance.get<Category[]>("/category"); 
  return response.data;
};

// Get one category
export const getOneCategory = async (id: string): Promise<Category> => {
  const response = await axiosInstance.get<Category>(`/category/${id}`); 
  return response.data;
};

// Create a new category
export const createCategory = async (newCategory: Category): Promise<Category> => {
  const response = await axiosInstance.post<Category>("/category", newCategory); 
  return response.data;
};

// Update an existing category
export const updateCategory = async (id: string, updatedCategory: Category): Promise<Category> => {
  const response = await axiosInstance.put<Category>(`/category/${id}`, updatedCategory);
  return response.data;
};

// Delete a category
export const deleteCategory = async (id: string): Promise<void> => {
  await axiosInstance.delete(`/category/${id}`);
};

