import React from "react";
import { useTranslation } from "react-i18next";
import freeTrial from "../assets/free.webp";
import affordablePrices from "../assets/affordablePrices.webp";
import verifiedTeachers from "../assets/verifiedTeachers.webp";
import guarantee from "../assets/guarantee.webp";
import differentLessons from "../assets/differentLessons.webp";
import improvement from "../assets/improvement.webp";

function FeaturesSection() {
  const { t } = useTranslation();

  const features = [
    {
      title: t("featuresSection.features.differentLessons.title"),
      description: t("featuresSection.features.differentLessons.description"),
      icon: (
        <img
          src={differentLessons}
          alt={t("featuresSection.features.differentLessons.title")}
          className="w-32 h-32 mx-auto"
        />
      ),
    },
    {
      title: t("featuresSection.features.freeTrial.title"),
      description: t("featuresSection.features.freeTrial.description"),
      icon: (
        <img
          src={freeTrial}
          alt={t("featuresSection.features.freeTrial.title")}
          className="w-32 h-32 mx-auto"
        />
      ),
    },
    {
      title: t("featuresSection.features.improvement.title"),
      description: t("featuresSection.features.improvement.description"),
      icon: (
        <img
          src={improvement}
          alt={t("featuresSection.features.improvement.title")}
          className="w-32 h-32 mx-auto"
        />
      ),
    },
    {
      title: t("featuresSection.features.guarantee.title"),
      description: t("featuresSection.features.guarantee.description"),
      icon: (
        <img
          src={guarantee}
          alt={t("featuresSection.features.guarantee.title")}
          className="w-32 h-32 mx-auto"
        />
      ),
    },
    {
      title: t("featuresSection.features.affordablePrices.title"),
      description: t("featuresSection.features.affordablePrices.description"),
      icon: (
        <img
          src={affordablePrices}
          alt={t("featuresSection.features.affordablePrices.title")}
          className="w-32 h-32 mx-auto"
        />
      ),
    },
    {
      title: t("featuresSection.features.verifiedTeachers.title"),
      description: t("featuresSection.features.verifiedTeachers.description"),
      icon: (
        <img
          src={verifiedTeachers}
          alt={t("featuresSection.features.verifiedTeachers.title")}
          className="w-32 h-32 mx-auto"
        />
      ),
    },
  ];

  return (
    <div className="py-16 bg-gray-100">
      <div className="container mx-auto sm:px-0 px-5">
        <div className="text-center mb-12">
          <h2 className="text-2xl sm:text-3xl lg:text-4xl font-bold mt-2">
            {t("featuresSection.title")}
          </h2>
          <p className="text-white-500 mt-4">
            {t("featuresSection.subtitle")}
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {features.map((feature, index) => (
            <div
              key={index}
              data-aos="fade-up"
              className="bg-white shadow-md rounded-lg p-6 text-center"
            >
              <div className="text-4xl mb-4">{feature.icon}</div>
              <h3 className="text-lg font-semibold text-gray-500">
                {feature.title}
              </h3>
              <p className="text-gray-500 mt-2">{feature.description}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default FeaturesSection;
