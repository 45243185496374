import axiosInstance from "./../axios";

export interface EntrollClass {
  classId: string;
  isConfirmed: boolean;
}

// POST request to enroll in a class
export const enrollInClass = async (enrollmentData: EntrollClass): Promise<void> => {
  try {
    const response = await axiosInstance.post("/manual-purchase/class", enrollmentData);
    console.log("Enrollment successful:", response.data);
  } catch (error) {
    console.error("Enrollment failed:", error);
    throw error; // Rethrow the error if you need to handle it elsewhere
  }
};
