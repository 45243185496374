import React from "react";

interface Review {
  id: number;
  content: string;
  author: string;
  date: string;
  type: "class" | "teacher";
}

const reviews: Review[] = [
  {
    id: 1,
    content: "My son loves this class and the teacher!",
    author: "Ms. H",
    date: "Jan 20",
    type: "class",
  },
  {
    id: 2,
    content:
      "Great Class. The class is dynamic and entertaining, and it provides children with the opportunity to explore the world of acting.",
    author: "Luz",
    date: "Apr 16, 2023",
    type: "class",
  },
  {
    id: 3,
    content:
      "Every lesson covers excellent well selected sections of popular professional...",
    author: "An Outschool parent",
    date: "Apr 12, 2022",
    type: "teacher",
  },
  {
    id: 4,
    content:
      "I would recommend this class for any young actor that wants help with learning how to show emotions and expression...",
    author: "Miranda K.",
    date: "Feb 19, 2022",
    type: "teacher",
  },
];

const ReviewsSection: React.FC = () => {
  return (
    <div className="container mx-auto p-6">
      <h2 className="text-2xl font-semibold">Reviews</h2>
      <div className="mt-4">
        <h3 className="text-lg font-bold">For this class (9)</h3>
        <div>
          {reviews
            .filter((review) => review.type === "class")
            .map((review) => (
              <div
                key={review.id}
                className="mt-2 p-4 border border-gray-300 rounded-lg"
              >
                <p className="text-gray-800">{review.content}</p>
                <p className="text-gray-500 text-sm">
                  {review.author} on {review.date}
                </p>
              </div>
            ))}
        </div>
      </div>
      {/* <div className="mt-6">
        <h3 className="text-lg font-bold">For this teacher (1356)</h3>
        <div>
          {reviews
            .filter((review) => review.type === "teacher")
            .map((review) => (
              <div
                key={review.id}
                className="mt-2 p-4 border border-gray-300 rounded-lg"
              >
                <p className="text-gray-800">{review.content}</p>
                <p className="text-gray-500 text-sm">
                  {review.author} on {review.date}
                </p>
              </div>
            ))}
        </div> 
      </div>*/}
    </div>
  );
};

export default ReviewsSection;
