import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { Link } from "react-router-dom";

const CustomPackageCard: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className="bg-white py-8 px-5 mt-6 shadow-md rounded-lg text-center flex flex-col gap-2 items-center justify-center">
      <div className="flex justify-center items-center">
        <h2 className="text-xl sm:text-2xl font-semibold text-gray-800 mb-4">
          {t("pricing.customOffer")}
        </h2>
      </div>
      <p className="text-gray-600 sm:text-base text-sm">
        {t("pricing.description")}
      </p>
      <Link
        to="/contact-us"
        className="bg-purple-600 hover:bg-purple-700 text-white py-2 px-4 rounded-lg mt-2"
      >
        {t("pricing.contactUs")}
      </Link>
    </div>
  );
};

type Plan = {
  name: string;
  classes: number;
  price: string;
  pricePerHour: string;
  details: string[];
  isPopular?: boolean;
};

const plans: Plan[] = [
  {
    name: "pricing.plans.lesson8.name", // Translation key for 8 Lessons
    classes: 8,
    price: "pricing.plans.lesson8.price", // Translation key for price
    pricePerHour: "pricing.plans.lesson8.pricePerHour", // Translation key for price per hour
    details: [
      "pricing.plans.lesson8.details.1", // Translation key for details
      "pricing.plans.lesson8.details.2",
      "pricing.plans.lesson8.details.3",
      "pricing.plans.lesson8.details.4",
    ],
    isPopular: false,
  },
  {
    name: "pricing.plans.lesson12.name",
    classes: 12,
    price: "pricing.plans.lesson12.price",
    pricePerHour: "pricing.plans.lesson12.pricePerHour",
    details: [
      "pricing.plans.lesson12.details.1",
      "pricing.plans.lesson12.details.2",
      "pricing.plans.lesson12.details.3",
      "pricing.plans.lesson12.details.4",
    ],
    isPopular: true,
  },
  {
    name: "pricing.plans.lesson16.name",
    classes: 16,
    price: "pricing.plans.lesson16.price",
    pricePerHour: "pricing.plans.lesson16.pricePerHour",
    details: [
      "pricing.plans.lesson16.details.1",
      "pricing.plans.lesson16.details.2",
      "pricing.plans.lesson16.details.3",
      "pricing.plans.lesson16.details.4",
    ],
  },
];

function PricingPlans() {
  const { t } = useTranslation();
  const [selectedPlan, setSelectedPlan] = useState<string>(plans[0].name);

  return (
    <>
      <Navbar />
      <div className="py-6 bg-gray-100">
        <div className="container mx-auto px-5 sm:px-0">
          <h2 className="text-center text-2xl sm:text-3xl font-bold mb-4">
            {t("pricing.title")}
          </h2>
          <p className="text-center mb-12">{t("pricing.description")}</p>
          <div className="flex justify-center gap-7 flex-wrap items-center">
            {plans.map((plan) => (
              <div
                key={t(plan.name)}
                className={`relative bg-purple-600 text-white rounded-lg shadow-lg w-64 p-6 flex flex-col items-center cursor-pointer
                ${t(plan.name) === selectedPlan ? "ring-2 ring-yellow-500" : ""}
                ${plan.isPopular ? "border-4 border-yellow-500" : ""}`}
                onClick={() => setSelectedPlan(t(plan.name))}
                role="button"
                aria-label={`Select the plan for ${t(plan.name)}`}
                tabIndex={0}
              >
                {plan.isPopular && (
                  <div className="absolute top-0 -mt-4 bg-yellow-500 text-black rounded-tl-lg rounded-tr-lg px-4 py-1 text-sm font-bold">
                    {t("pricing.popular")}
                  </div>
                )}
                <h3 className="text-2xl font-bold mb-2">{t(plan.name)}</h3>
                <p className="text-lg mb-2">{t(plan.price)}</p>
                <p className="text-sm mb-2">{t(plan.pricePerHour)}</p>
                <button className="bg-orange-500 text-white font-semibold py-2 px-6 rounded-lg hover:bg-orange-600 mt-4">
                  {t("pricing.trialLesson")}
                </button>

                <ul className="text-sm ml-6 mt-4 space-y-1 text-left">
                  {plan.details.map((detail, index) => (
                    <li className="mt-6" key={index}>
                      {t(detail)}
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
          <CustomPackageCard />
        </div>
      </div>
      <Footer />
    </>
  );
}

export default PricingPlans;
