import axios from "axios";
// import https from "https";

interface AxiosResponse<T = any> {
  data: T;
  status: number;
  statusText: string;
  headers: any;
  config: any;
  request?: any;
}

export const ACCESS_TOKEN = "access_token";
export const REFRESH_TOKEN = "refresh_token";

// api paths
export const REGISTER_PATH = "/user/register";
export const baseURL = process.env.REACT_APP_BASE_URL;

export const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  // httpsAgent: new https.Agent({
  //   rejectUnauthorized: false,  // Disables SSL certificate validation (not for production)
  // }),
});

// Adds bearer token to requests
api.interceptors.request.use((config) => {
  const token = localStorage.getItem(ACCESS_TOKEN);

  // Initialize config.headers if it's undefined
  if (!config.headers) {
    return config;
  }

  if (token && config.url !== "/auth/auth/userAccessToken") {
    config.headers["Authorization"] = `Bearer ${token}`;
  }

  return config;
});

api.interceptors.response.use(
  (response: AxiosResponse<any>) => {
    return response.data;
  },
  (error) => {
    if (error.response?.status === 401) {
      localStorage.removeItem(ACCESS_TOKEN);
    }
    return Promise.reject(error);
  }
);

export const setAccessTokenToLocalStorage = (token: string) => {
  localStorage.setItem(ACCESS_TOKEN, token);
};

export const getAccessTokenFromLocalStorage = () => {
  return localStorage.getItem(ACCESS_TOKEN);
};

export const setRefreshTokenToLocalStorage = (token: string) => {
  localStorage.setItem(REFRESH_TOKEN, token);
};

export const getRefreshTokenFromLocalStorage = () => {
  return localStorage.getItem(REFRESH_TOKEN);
};
