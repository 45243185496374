import React, { useState } from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import axiosInstance from "../common/api/axios";
import { useTranslation } from "react-i18next";

type FormData = {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  message: string;
  countryCode: string;
};

const MySwal = withReactContent(Swal);

const ContactForm: React.FC = () => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState<FormData>({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    message: "",
    countryCode: "",
  });

  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    setErrorMessage("");
    const { firstName, lastName, email, message, phoneNumber, countryCode } =
      formData;

    try {
      const newData = {
        name: `${firstName} ${lastName}`,
        email,
        phoneNumber,
        message,
        countryCode,
      };
      const response = await axiosInstance.post("/mail", newData);

      if (response.status === 201) {
        MySwal.fire({
          icon: "success",
          title: "Xabar muvaffaqiyatli yuborildi!",
          text: "Biz siz bilan tez orada bog'lanamiz.",
        });

        setFormData({
          firstName: "",
          lastName: "",
          email: "",
          phoneNumber: "",
          message: "",
          countryCode: "",
        });
      } else {
        throw new Error("Failed to send the message.");
      }
    } catch (error: any) {
      console.error("Error:", error);
      setErrorMessage(
        error?.response?.data?.message || t("contactForm.error.default")
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="max-w-4xl mx-auto p-6 bg-white rounded-lg shadow-lg m-8">
        <h2 className="text-2xl font-semibold text-center text-orange-500 mb-4">
          {t("contactForm.helpTitle")}
        </h2>
        <p className="text-center text-gray-600 mb-8">
          {t("contactForm.helpDescription")}
        </p>
        <form onSubmit={handleSubmit} className="space-y-4">
          {errorMessage && (
            <div className="text-red-500 text-center mb-4">{errorMessage}</div>
          )}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <label className="block text-gray-700">
                {t("contactForm.firstName")}
                <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                name="firstName"
                value={formData.firstName}
                onChange={handleChange}
                className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-200"
                required
              />
            </div>
            <div>
              <label className="block text-gray-700">
                {t("contactForm.lastName")}
                <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                name="lastName"
                value={formData.lastName}
                onChange={handleChange}
                className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-200"
                required
              />
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <label className="block text-gray-700">
                {t("contactForm.email")}
                <span className="text-red-500">*</span>
              </label>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-200"
                required
              />
            </div>

            <div>
              <label className="block text-gray-700">
                {t("contactForm.phone")}
                <span className="text-red-500">*</span>
              </label>
              <div className="flex">
                <select
                  name="countryCode"
                  value={formData.countryCode}
                  onChange={handleChange}
                  className="px-4 py-2 border rounded-l-lg focus:outline-none focus:ring-2 focus:ring-blue-200"
                >
                  <option value="US">United States</option>
                  <option value="UZ">Uzbekistan</option>
                </select>
                <input
                  type="tel"
                  name="phoneNumber"
                  value={formData.phoneNumber}
                  onChange={handleChange}
                  className="w-full px-4 py-2 border rounded-r-lg focus:outline-none focus:ring-2 focus:ring-blue-200"
                  required
                />
              </div>
            </div>
          </div>
          <div>
            <label className="block text-gray-700">
              {t("contactForm.message")}
              <span className="text-red-500">*</span>
            </label>
            <textarea
              name="message"
              value={formData.message}
              onChange={handleChange}
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-200"
              rows={4}
              required
            />
          </div>
          <div className="text-center">
            <button
              type="submit"
              className="px-6 py-3 bg-orange-500 text-white font-semibold rounded-lg shadow-md hover:bg-orange-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
              disabled={loading}
            >
              {loading ? t("contactForm.submitting") : t("contactForm.submit")}
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default ContactForm;
