import React from "react";
import { useTranslation } from "react-i18next";

function Footer() {
  const { t } = useTranslation();

  return (
    <footer className="bg-gray-100 py-4 px-8">
      <div className="container mx-auto flex flex-wrap gap-10 justify-between text-gray-700">
        {/* Left Section */}
        <div>
          <h2 className="text-2xl font-bold mb-2 text-orange-500">
            {t("footer.title")}
          </h2>
          <p className="mb-4">{t("footer.description")}</p>
          <p className="font-semibold">{t("footer.contact")}</p>
          <p>{t("footer.email")}</p>
          <p>{t("footer.phone")}</p>
          <p className="font-semibold mt-4">{t("footer.address")}</p>
        </div>

        {/* Middle Section */}
        <div>
          <h3 className="text-xl font-semibold mb-2">
            {t("footer.quickLinks")}
          </h3>
          <ul>
            <li>{t("footer.subjects")}</li>
            <li>{t("footer.lessons")}</li>
            <li>{t("footer.prices")}</li>
            <li>{t("footer.howItWorks")}</li>
            <li>{t("footer.contactUs")}</li>
          </ul>
        </div>

        {/* Legal documents section */}
        <div>
          <h3 className="text-xl font-semibold mb-2">
            {t("footer.legalDocuments")}
          </h3>
          <ul>
            <li>{t("footer.termsOfService")}</li>
            <li>{t("footer.privacyPolicy")}</li>
            <li>{t("footer.refundPolicy")}</li>
            <li>{t("footer.cancellationPolicy")}</li>
          </ul>
        </div>

        {/* Right Section */}
        <div>
          <h3 className="text-xl font-semibold mb-2">
            {t("footer.socialNetworks")}
          </h3>
          <ul>
            <li>
              <a href="https://www.facebook.com/darstopuz">
                {t("footer.facebook")}
              </a>
            </li>
            <li>
              <a href="https://www.instagram.com/darstop.uz">
                {t("footer.instagram")}
              </a>
            </li>
            <li>
              <a href="https://t.me/darstopuz">{t("footer.telegram")}</a>
            </li>
          </ul>
        </div>
      </div>

      {/* Copyright Section */}
      <div className="text-center text-gray-500 mt-8">
        {t("footer.copyright")}
      </div>
    </footer>
  );
}

export default Footer;
