import React from "react";
import { useTranslation } from "react-i18next";

function SubjectsSection() {
  const { t } = useTranslation();

  const categories = [
    {
      title: t("subjectsSection.categories.math.title"),
      description: t("subjectsSection.categories.math.description"),
      bgColor: "bg-gradient-to-r from-yellow-400 to-amber-400",
      icon: "🧮",
    },
    {
      title: t("subjectsSection.categories.coding.title"),
      description: t("subjectsSection.categories.coding.description"),
      bgColor: "bg-gradient-to-r from-purple-400 to-purple-600",
      icon: "🖥️",
    },
    {
      title: t("subjectsSection.categories.english.title"),
      description: t("subjectsSection.categories.english.description"),
      bgColor: "bg-gradient-to-r from-yellow-200 to-orange-400",
      icon: "🗣️",
    },
    {
      title: t("subjectsSection.categories.art.title"),
      description: t("subjectsSection.categories.art.description"),
      bgColor: "bg-gradient-to-r from-pink-200 to-pink-400",
      icon: "🕺",
    },
  ];

  return (
    <div className="py-16 bg-gray-200">
      <div className="text-center mb-12">
        <h2 className="text-center text-2xl sm:text-3xl lg:text-4xl font-bold mb-8">
          {t("subjectsSection.title")}
        </h2>
        <p className="text-white-500 mt-4">
          {t("subjectsSection.description")}
        </p>
      </div>

      <div className="container mx-auto md:px-0 px-5 flex flex-wrap justify-center gap-5">
        {categories.map((category, index) => (
          <div
            key={index}
            data-aos="fade-up"
            className={`${category.bgColor} hover:cursor-pointer transition-opacity duration-200 rounded-lg p-6 text-white flex flex-col items-center w-full sm:w-64`}
          >
            <div className="text-4xl mb-4">{category.icon}</div>
            <h3 className="text-2xl font-bold mb-2">{category.title}</h3>
            <p className="text-base sm:text-lg text-center">
              {category.description}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default SubjectsSection;
