import React, { useState } from "react";

const FreeTrialClass: React.FC = () => {
  const [selectedDate, setSelectedDate] = useState<string>("");
  const [selectedTime, setSelectedTime] = useState<string>("");
  const [selectedProgram, setSelectedProgram] = useState<string>("");
  const [grade, setGrade] = useState<string>("");

  const handleDateChange = (date: string) => setSelectedDate(date);
  const handleTimeChange = (time: string) => setSelectedTime(time);
  const handleProgramChange = (program: string) => setSelectedProgram(program);
  const handleGradeChange = (grade: string) => setGrade(grade);

  const handleSubmit = () => {
    // Handle form submission
    alert("Trial class scheduled!");
  };

  return (
    <div className="container mx-auto p-8">
      <h1 className="text-3xl font-bold text-center mb-4">
        Book Your Free Coding Class
      </h1>
      <div className="text-center mb-8">
        <p className="text-orange-500 text-xl">
          2,000,000+ coding classes completed
        </p>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        <div>
          <h2 className="text-lg font-semibold mb-2">Select a date</h2>
          <div className="flex justify-around">
            {/* Example date buttons */}
            {["03 SEP", "04 SEP", "05 SEP"].map((date) => (
              <button
                key={date}
                onClick={() => handleDateChange(date)}
                className={`px-4 py-2 border ${
                  selectedDate === date
                    ? "bg-blue-500 text-white"
                    : "bg-gray-100"
                }`}
              >
                {date}
              </button>
            ))}
          </div>
        </div>
        <div>
          <h2 className="text-lg font-semibold mb-2">Select a time</h2>
          <div className="grid grid-cols-3 gap-2">
            {/* Example time buttons */}
            {["01:00 PM", "02:00 PM", "03:00 PM", "04:00 PM", "05:00 PM"].map(
              (time) => (
                <button
                  key={time}
                  onClick={() => handleTimeChange(time)}
                  className={`px-4 py-2 border ${
                    selectedTime === time
                      ? "bg-blue-500 text-white"
                      : "bg-gray-100"
                  }`}
                >
                  {time}
                </button>
              )
            )}
          </div>
        </div>
      </div>

      <div className="mt-8">
        <h2 className="text-lg font-semibold mb-2">Program</h2>
        <div className="flex justify-around">
          {["Coding", "Math", "ELA"].map((program) => (
            <button
              key={program}
              onClick={() => handleProgramChange(program)}
              className={`px-4 py-2 border ${
                selectedProgram === program
                  ? "bg-blue-500 text-white"
                  : "bg-gray-100"
              }`}
            >
              {program}
            </button>
          ))}
        </div>
      </div>

      <div className="mt-8">
        <h2 className="text-lg font-semibold mb-2">Select Grade</h2>
        <div className="flex justify-around">
          {["1-2", "3-4", "5-6", "7-8", "9-12"].map((g) => (
            <button
              key={g}
              onClick={() => handleGradeChange(g)}
              className={`px-4 py-2 border ${
                grade === g ? "bg-blue-500 text-white" : "bg-gray-100"
              }`}
            >
              Grades {g}
            </button>
          ))}
        </div>
      </div>

      <div className="mt-8 text-center">
        <button
          onClick={handleSubmit}
          className="bg-orange-500 text-white px-8 py-3 rounded"
        >
          Confirm Slot
        </button>
      </div>
    </div>
  );
};

export default FreeTrialClass;
