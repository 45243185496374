import React from "react";
import { useTranslation } from "react-i18next";

interface Instructor {
  name: string;
  title: string;
  rating: string;
  tags: string[];
  image: string; // Don't forget to add the image property
}

function TopInstructorsSection() {
  const { t } = useTranslation();

  const instructors: Instructor[] = [
    {
      name: t("instructor.instructors.0.name"),
      title: t("instructor.instructors.0.title"),
      rating: t("instructor.instructors.0.rating"),
      tags: ["#Math" , "#Math"], // Convert to array if not already
      image:
        "https://wpappointify.com/tutorly/elementor/demo3/wp-content/uploads/avatars/4/t4-bpthumb.png",
    },
    {
      name: t("instructor.instructors.1.name"),
      title: t("instructor.instructors.1.title"),
      rating: t("instructor.instructors.1.rating"),
      tags: ["#Math" , "#Math"], // Same for this instructor
      image:
        "https://wpappointify.com/tutorly/elementor/demo3/wp-content/uploads/avatars/3/t3-bpthumb.png",
    },
    {
      name: t("instructor.instructors.2.name"),
      title: t("instructor.instructors.2.title"),
      rating: t("instructor.instructors.2.rating"),
      tags: ["#Math" , "#Math"], // Same for this instructor
      image:
        "https://wpappointify.com/tutorly/elementor/demo3/wp-content/uploads/avatars/5/t5-bpthumb.png",
    },
  ];

  return (
    <div className="py-16 bg-gray-100">
      <div className="container mx-auto md:px-0 px-5">
        <h2
          className="text-center text-2xl font-semibold mb-8"
          data-aos="fade-up"
        >
          {t("instructor.title")}
        </h2>
        <div className="flex justify-center gap-5 flex-wrap">
          {instructors.map((instructor, index) => (
            <div
              key={index}
              className="bg-white rounded-lg shadow-lg w-full sm:w-72"
              data-aos="fade-up"
            >
              <img
                src={instructor.image}
                alt={instructor.name}
                className="rounded-t-lg h-64 w-full object-cover"
              />
              <div className="p-4">
                <h3 className="text-lg font-semibold text-gray-600">
                  {instructor.name}
                </h3>
                <p className="text-gray-600">{instructor.title}</p>
                <span className="text-yellow-500 flex items-center">
                  <svg
                    className="w-5 h-5 mr-1"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.518 4.674a1 1 0 00.95.69h4.917c.969 0 1.371 1.24.588 1.81l-3.973 2.884a1 1 0 00-.364 1.118l1.518 4.674c.3.921-.755 1.688-1.539 1.118L10 15.347l-3.974 2.884c-.784.57-1.838-.197-1.539-1.118l1.518-4.674a1 1 0 00-.364-1.118L1.668 10.1c-.784-.57-.38-1.81.588-1.81h4.917a1 1 0 00.95-.69l1.518-4.674z" />
                  </svg>
                  {instructor.rating}
                </span>
                <div className="mt-4">
                  {instructor.tags.map((tag, index) => (
                    <span
                      key={index}
                      className="bg-gray-100 text-gray-600 text-sm px-2 py-1 rounded-full mr-2"
                    >
                      {tag}
                    </span>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default TopInstructorsSection;
