import React from "react";
import { useTranslation } from "react-i18next";
import codingForKidsImage from "../assets/Yosh_dasturchilar_coding_course2.png";

function TopClassesSection() {
  const { t } = useTranslation();

  const classes = [
    {
      title: t("topClassesSection.classes.singing.title"),
      description: t("topClassesSection.classes.singing.description"),
      instructor: t("topClassesSection.classes.singing.instructor"),
      price: t("topClassesSection.classes.singing.price"),
      rating: t("topClassesSection.classes.singing.rating"),
      instructorMajor: t("topClassesSection.classes.singing.instructorMajor"),
      image:
        "https://wpappointify.com/tutorly/elementor/demo3/wp-content/uploads/group-avatars/6/sing-bpfull.png",
      instructorImage:
        "https://wpappointify.com/tutorly/elementor/demo3/wp-content/uploads/avatars/3/t3-bpthumb.png",
    },
    {
      title: t("topClassesSection.classes.englishSpeaking.title"),
      description: t("topClassesSection.classes.englishSpeaking.description"),
      instructor: t("topClassesSection.classes.englishSpeaking.instructor"),
      price: t("topClassesSection.classes.englishSpeaking.price"),
      rating: t("topClassesSection.classes.englishSpeaking.rating"),
      instructorMajor: t(
        "topClassesSection.classes.englishSpeaking.instructorMajor"
      ),
      image:
        "https://wpappointify.com/tutorly/elementor/demo3/wp-content/uploads/group-avatars/5/mandarin-bpfull.png",
      instructorImage:
        "https://wpappointify.com/tutorly/elementor/demo3/wp-content/uploads/avatars/5/t5-bpthumb.png",
    },
    {
      title: t("topClassesSection.classes.codingForKids.title"),
      description: t("topClassesSection.classes.codingForKids.description"),
      instructor: t("topClassesSection.classes.codingForKids.instructor"),
      price: t("topClassesSection.classes.codingForKids.price"),
      rating: t("topClassesSection.classes.codingForKids.rating"),
      instructorMajor: t(
        "topClassesSection.classes.codingForKids.instructorMajor"
      ),
      image: codingForKidsImage,
      instructorImage:
        "https://wpappointify.com/tutorly/elementor/demo3/wp-content/uploads/avatars/5/t5-bpthumb.png",
    },
  ];

  return (
    <div className="py-16 bg-gray-100">
      <div className="container mx-auto md:px-0 px-5">
        <div className="text-center mb-12" data-aos="fade-up">
          <h2 className="text-2xl sm:text-3xl lg:text-4xl font-bold mt-2">
            {t("topClassesSection.title")}
          </h2>
          <p className="text-white-500 mt-4">
            {t("topClassesSection.description")}
          </p>
        </div>
        <div className="flex justify-center gap-5 flex-wrap">
          {classes.map((classItem, index) => (
            <div
              key={index}
              className="bg-white rounded-lg shadow-lg p-4 w-80"
              data-aos="fade-up"
            >
              <img
                src={classItem.image}
                alt={classItem.title}
                className="rounded-t-lg"
              />
              <div className="p-4">
                <h3 className="text-lg text-gray-500 font-bold">
                  {classItem.title}
                </h3>
                <p className="text-gray-600">{classItem.description}</p>
                <div className="flex items-center mt-4">
                  <img
                    src={classItem.instructorImage}
                    alt={classItem.instructor}
                    className="w-10 h-10 rounded-full mr-3"
                  />
                  <div>
                    <p className="text-sm font-medium">
                      {classItem.instructor}
                    </p>
                    <p className="text-sm text-gray-500">
                      {classItem.instructorMajor}
                    </p>
                  </div>
                </div>
                <div className="flex justify-between items-center mt-4">
                  <span className="text-yellow-500 flex items-center">
                    <svg
                      className="w-5 h-5 mr-1"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.518 4.674a1 1 0 00.95.69h4.917c.969 0 1.371 1.24.588 1.81l-3.973 2.884a1 1 0 00-.364 1.118l1.518 4.674c.3.921-.755 1.688-1.539 1.118L10 15.347l-3.974 2.884c-.784.57-1.838-.197-1.539-1.118l1.518-4.674a1 1 0 00-.364-1.118L1.668 10.1c-.784-.57-.38-1.81.588-1.81h4.917a1 1 0 00.95-.69l1.518-4.674z" />
                    </svg>
                    {classItem.rating}
                  </span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default TopClassesSection;
