import React from "react";
import { useTranslation } from "react-i18next";
import ListingsPage from "../components/ListingsPage";
import Navbar from "../components/Navbar";
import ClassListingHeroSection from "../components/class-details/ClassListingHeroSection";
import Footer from "../components/Footer";
import codingForKids from "../assets/coding4kids.png";
import adv1 from "../assets/adv-1.svg";
import adv2 from "../assets/adv-2.svg";
import adv3 from "../assets/adv-3.svg";
import adv4 from "../assets/adv-4.svg";
import myprofile from "./../assets/myProfilePicture.png";
import person from "../assets/EnglishClass.webp";
import About from "../components/class-details/about";
import WhyChooseUs from "../components/class-details/why-choose-us";
import Pricing from "../components/class-details/pricing";
import Testimonials from "../components/class-details/testimonials";
import Instructor from "../components/class-details/instructor";
import Advantages from "../components/class-details/advantages";
import FAQ from "../components/class-details/FAQ";
import Consultations from "../components/class-details/consultation";

const CodingClassListings: React.FC = () => {
  const { t } = useTranslation();

  // Coding classes array with translation keys
  const codingClasses = [
    {
      id: 1,
      title: t("codingClasses.listings.title"), // Translated title
      description: t("codingClasses.listings.description"), // Translated description
      ageRange: t("codingClasses.listings.ageRange"), // Translated age range
      imageUrl: codingForKids,
      rating: "*****",
    },
    // Add more class items as needed
  ];

  // Tags for the hero section
  const tags = [
    t("tagsforart1", { ns: "classes" }), // Translated tag with namespace
    t("tagsforart2", { ns: "classes" }), // Example for dynamic age range
  ];

  const advantages = [
    {
      id: 1,
      name: t("advantages1", { ns: "classes" }),
      icon: adv1,
    },
    {
      id: 2,
      name: t("advantages2", { ns: "classes" }),
      icon: adv2,
    },
    {
      id: 3,
      name: t("advantages3", { ns: "classes" }),
      icon: adv3,
    },
    {
      id: 4,
      name: t("advantages4", { ns: "classes" }),
      icon: adv4,
    },
  ];

  const plans = [
    {
      title: "starter",
      price: "Free",
      features: ["Basic support", "5 projects", "Community access"],
    },
    {
      title: "pro",
      price: "$9.99/mo",
      features: ["Priority support", "Unlimited projects", "Advanced tools"],
    },
    {
      title: "plus",
      price: "$19.99/mo",
      features: [
        "Dedicated support",
        "Unlimited projects",
        "All tools included",
      ],
    },
  ];

  const testimonials = [
    {
      name: "Abror Tulaev",
      date: "8 aprel 2024 yil",
      text: "Darstopdan juda minnatdorman. Darslar sifati hamda o'qituvchilarning dars o'tishi usullariga gap yo'q!",
      rating: 5,
      image: person,
    },
    {
      name: "Said Karimov",
      date: "6 iyun 2024 yil",
      text: "Darstopda Amaliyot loyihasi ko'plab amaliy bilimlarni beradi",
      rating: 5,
      image: person,
    },
    {
      name: "Jamoliddin Baratov",
      date: "6 iyun 2024 yil",
      text: "Darstopda o'glim hali endi 2 oy o'qidi, lekin o'zini mini o'yinlarini yarata boshladi.",
      rating: 5,
      image: person,
    },
    // Add more testimonials as needed
  ];

  const profile = {
    title: t("tutor.coding.title", { ns: "classes" }),
    body: t("tutor.coding.body", { ns: "classes" }),
    image: myprofile,
    advantages: t("tutor.coding.advantages", {
      ns: "classes",
      returnObjects: true,
    }) as string[],
  };

  // Optional filters (uncomment if needed)
  const filters = [
    t("filters.dayOrTime"),
    t("filters.date"),
    t("filters.classSize"),
    t("filters.anyPrice"),
    t("filters.age"),
    t("filters.more"),
  ];

  return (
    <>
      <Navbar />
      <ClassListingHeroSection
        title={t("codingClasses.title")}
        description={t("codingClasses.description")}
        reviews={t("codingClasses.reviews")}
        tags={tags} // Pass tags to hero section
        advantages={advantages}
        background={codingForKids}
      />
      <About />
      <WhyChooseUs advantagesTitle={t("whychooseustitle", { ns: "classes" })} />
      {/* <Pricing plans={plans} /> */}
      <Testimonials testimonials={testimonials} />
      <Instructor profile={profile} />
      <Advantages />
      <FAQ />
      <Consultations />
      {/* <ListingsPage
        classes={codingClasses}
        // filters={filters} // Pass filters to ListingsPage if needed
      /> */}
      <Footer />
    </>
  );
};

export default CodingClassListings;
