import axiosInstance from "../axios";

// Define the GroupData interface
export interface GroupData {
  _id?: string;
  title: string;
  description: string;
  imageUrl: string;
  startDate?: any;
  category?: string;
  price?: any;
  teacherId?: any;
  link?: string;
  isPublished?: string;
  isActive?: string;
}

// Get public groups
export const getPublicGroups = async (): Promise<GroupData[]> => {
  const response = await axiosInstance.get<GroupData[]>("/group/public"); // Add <GroupData[]>
  return response.data;
};

// Get own groups for teacher
export const getOwnGroups = async (): Promise<GroupData> => {
  const response = await axiosInstance.get<GroupData>("/group/own-groups"); // Add <GroupData>
  return response.data;
};

// Get single group by ID
export const getOneGroup = async (id: any): Promise<GroupData> => {
  const response = await axiosInstance.get<GroupData>(`/group/${id}`); // Add <GroupData>
  return response.data;
};

// POST request to create a new group
export const createGroup = async (newGroup: GroupData): Promise<GroupData> => {
  const response = await axiosInstance.post<GroupData>("/group", newGroup); // Add <GroupData>
  return response.data;
};

// PUT request to update group data
export const updateGroup = async (
  id: any,
  updatedGroup: GroupData
): Promise<GroupData> => {
  const response = await axiosInstance.patch<GroupData>(
    `/group/${id}`,
    updatedGroup
  ); // Add <GroupData>
  return response.data;
};

// DELETE request to remove a group
export const deleteGroup = async (id: any): Promise<void> => {
  await axiosInstance.delete(`/group/${id}`);
};
