// src/components/FAQ.tsx
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import grid1 from "./../../assets/grid-1.png";
import grid2 from "./../../assets/grid-2.png";
import grid3 from "./../../assets/grid-3.png";

const Advantages: React.FC = () => {
  const { t } = useTranslation();

  return (
    <section className="py-10 sm:py-10">
      <div className="container-fluid p-4 flex flex-col gap-10 md:gap-20 items-center">
        <h2 className="text-2xl md:text-3xl lg:text-4xl font-semibold text-center w-3/4">
          {t("advantagesTitle", { ns: "classes" })}
        </h2>
        <div className="grid gap-5 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 w-full md:w-4/5">
          <div className="cols-span-1 sm:col-span-2 lg:col-span-3 border-main border-2 px-7 py-10 rounded-3xl flex items-start relative sm:min-h-[220px] border-opacity-30">
            <div className="flex flex-col gap-3 w-full sm:w-2/5">
              <h3 className="font-medium text-base">
                {t("advantagesgrid.title1", { ns: "classes" })}
              </h3>
              <p className="font-normal opacity-80">
                {t("advantagesgrid.body1", { ns: "classes" })}
              </p>
            </div>
            <img
              src={grid1}
              alt="image-grid"
              className="absolute w-3/5 bottom-0 right-0 sm:block hidden"
            />
          </div>
          <div className="bg-[#d9ceff] px-7 py-10 rounded-3xl flex flex-col gap-3 items-start relative sm:min-h-[220px] border-opacity-50 ">
            <h3 className="font-medium text-base">
              {t("advantagesgrid.title2", { ns: "classes" })}
            </h3>
            <p className="font-normal opacity-80">
              {t("advantagesgrid.body2", { ns: "classes" })}
            </p>
          </div>
          <div className="cols-span-1 sm:col-span-2 lg:col-span-2 border-main border-2 px-7 py-10 rounded-3xl flex items-start relative sm:min-h-[220px] border-opacity-30">
            <div className="flex flex-col gap-3 w-full sm:w-3/5">
              <h3 className="font-medium text-base">
                {t("advantagesgrid.title3", { ns: "classes" })}
              </h3>
              <p className="font-normal opacity-80">
                {t("advantagesgrid.body3", { ns: "classes" })}
              </p>
            </div>
            <img
              src={grid2}
              alt="image-grid"
              className="absolute w-2/4 bottom-0 right-0 sm:block hidden"
            />
          </div>
          <div className="cols-span-1 sm:col-span-2 lg:col-span-2 border-main border-2 px-7 py-10 rounded-3xl flex items-start relative sm:min-h-[220px] border-opacity-30">
            <div className="flex flex-col gap-3 w-full sm:w-3/5">
              <h3 className="font-medium text-base">
                {t("advantagesgrid.title4", { ns: "classes" })}
              </h3>
              <p className="font-normal opacity-80">
                {t("advantagesgrid.body4", { ns: "classes" })}
              </p>
            </div>
            <img
              src={grid3}
              alt="image-grid"
              className="absolute w-1/3 bottom-10 right-10 sm:block hidden"
            />
          </div>
          <div className="bg-[#a8d7ff] px-7 py-10 rounded-3xl flex flex-col gap-3 items-start relative sm:min-h-[220px] border-opacity-50 ">
            <h3 className="font-medium text-base">
              {t("advantagesgrid.title5", { ns: "classes" })}
            </h3>
            <p className="font-normal opacity-80">
              {t("advantagesgrid.body5", { ns: "classes" })}
            </p>
          </div>
          <div className="bg-[#a8d7ff] px-7 py-10 rounded-3xl flex flex-col gap-3 items-start relative sm:min-h-[220px] border-opacity-50 ">
            <h3 className="font-medium text-base">
              {t("advantagesgrid.title6", { ns: "classes" })}
            </h3>
            <p className="font-normal opacity-80">
              {t("advantagesgrid.body6", { ns: "classes" })}
            </p>
          </div>
          <div className="border-main border-2 px-7 py-10 rounded-3xl flex flex-col gap-3 items-start relative sm:min-h-[220px] border-opacity-50 ">
            <h3 className="font-medium text-base">
              {t("advantagesgrid.title7", { ns: "classes" })}
            </h3>
            <p className="font-normal opacity-80">
              {t("advantagesgrid.body7", { ns: "classes" })}
            </p>
          </div>
          <div className="bg-[#d9ceff] px-7 py-10 rounded-3xl flex flex-col gap-3 items-start relative sm:min-h-[220px] border-opacity-50 ">
            <h3 className="font-medium text-base">
              {t("advantagesgrid.title8", { ns: "classes" })}
            </h3>
            <p className="font-normal opacity-80">
              {t("advantagesgrid.body8", { ns: "classes" })}
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Advantages;
