import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import FreeTrialModal from "./FreeTrialModal";
import ContactDetailsModal from "./ContactDetailsModal";
import { useTranslation } from "react-i18next";
import heroSection from "../assets/heroSectionImg.png";

function HeroSection() {
  const [isFreeTrialModalOpen, setIsFreeTrialModalOpen] =
    useState<boolean>(false);
  const [isContactModalOpen, setIsContactModalOpen] = useState<boolean>(false);

  const openFreeTrialModal = () => setIsFreeTrialModalOpen(true);
  const closeFreeTrialModal = () => setIsFreeTrialModalOpen(false);

  const openContactModal = () => {
    closeFreeTrialModal();
    setIsContactModalOpen(true);
  };

  const closeContactModal = () => setIsContactModalOpen(false);

  const navigate = useNavigate();
  const { t, i18n } = useTranslation(); // Destructure t and i18n from useTranslation

  return (
    <div className="bg-gray-200 text-white py-16">
      <div className="container mx-auto flex flex-col lg:flex-row items-center justify-between space-y-10 lg:space-y-0 lg:space-x-10 px-6">
        {/* Left Section - Text and Search Bar */}
        <div className="lg:w-1/2 text-left space-y-6">
          <h1
            className="text-3xl lg:text-5xl font-bold leading-tight text-gray-900"
            dangerouslySetInnerHTML={{ __html: t("banner.title") }}
          />
          <p className="text-lg text-gray-900">{t("banner.body")}</p>

          {/* Search Bar */}
          {/* <div className="flex sm:flex-row flex-col  w-full bg-white rounded-lg sm:rounded-full shadow-lg overflow-hidden">
            <input
              type="text"
              className="flex-grow px-4 py-2 text-black focus:outline-none"
              placeholder={`${t("banner.searchPlaceholder")}`}
            />
            <select className="px-4 py-2 border-l text-black focus:outline-none">
              <option>{t("banner.chooseCategory")}</option>
              <option>{t("banner.option1")}</option>
              <option>{t("banner.option2")}</option>
              <option>{t("banner.option3")}</option>
            </select>
            <button className="bg-orange-500 text-white px-6 py-2 hover:bg-orange-600">
              {t("banner.search")}
            </button>
          </div> */}

          {/* <p className="text-sm text-gray-500">{t("banner.freeButtonText")}</p> */}
          {/* Your Hero Section and other sections */}
          <a
            href="https://scheduler.zoom.us/darstop/free-trial-lesson"
            target="_blank"
          >
            <button
              // onClick={openContactModal}
              className="bg-orange-500 text-white px-4 py-2 rounded-lg mt-4"
            >
              {t("banner.freeButton")}
            </button>
          </a>

          {/* Free Trial Modal */}
          {isFreeTrialModalOpen && (
            <FreeTrialModal
              closeModal={closeFreeTrialModal}
              openContactModal={openContactModal}
            />
          )}
          {/* Contact Details Modal */}
          {isContactModalOpen && (
            <ContactDetailsModal closeModal={closeContactModal} />
          )}
        </div>

        {/* Right Section - Image and Floating Elements */}
        <div className="lg:w-1/2 relative sm:block hidden">
          <img
            src={heroSection}
            alt="Hero Section Image"
            className="max-w-full h-auto"
          />
          {/* Floating Cards */}
          {/* <div className="absolute top-0 right-10 2xl:right-0 transform translate-x-20 -translate-y-10 md:block hidden">
           <div className="bg-white p-4 shadow-lg rounded-lg">
              <h3 className="text-black font-semibold">Jerry Peterson</h3>
              <p className="text-gray-600">Kansas City, AZ</p>
            </div>
            <div className="bg-white p-4 shadow-lg rounded-lg mt-4">
              <h3 className="text-black font-semibold">Explore Top Talent</h3>
              <p className="text-gray-600">Meet our verified professionals</p>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default HeroSection;
