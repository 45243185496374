import { baseURL } from "../api";

export function formatDate(isoString: string) {
  const date = new Date(isoString);

  // Extract the components of the date
  const day = String(date.getUTCDate()).padStart(2, "0");
  const month = String(date.getUTCMonth() + 1).padStart(2, "0"); // Months are zero-indexed
  const year = date.getUTCFullYear();

  const hours = String(date.getUTCHours()).padStart(2, "0");
  const minutes = String(date.getUTCMinutes()).padStart(2, "0");

  // Combine into the desired format
  return `${day}/${month}/${year}-${hours}:${minutes}`;
}

export function getFilesUrl(imageUrl: any) {
  return `${baseURL + "/files/" + imageUrl}`;
}

export function formaterDate(data: string) {
  return new Date(data)
    .toLocaleString("sv-SE", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
    })
    .replace(" ", "T");
}
