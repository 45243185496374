import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import FreeTrialModal from "../FreeTrialModal";
import ContactDetailsModal from "../ContactDetailsModal";
import Button from "../custom/button";
import program from "../../assets/program.png";
import cursor from "../../assets/cursor.png";
import play from "../../assets/play.png";
import file from "../../assets/file.png";

interface HeroSectionProps {
  title: string;
  description: string;
  reviews: string;
  tags: string[];
  advantages: any[];
  background?: string;
}

const ClassListingHeroSection: React.FC<HeroSectionProps> = ({
  title,
  description,
  reviews,
  tags,
  advantages,
  background,
}) => {
  const [isFreeTrialModalOpen, setIsFreeTrialModalOpen] =
    useState<boolean>(false);
  const [isContactModalOpen, setIsContactModalOpen] = useState<boolean>(false);

  const openFreeTrialModal = () => setIsFreeTrialModalOpen(true);
  const closeFreeTrialModal = () => setIsFreeTrialModalOpen(false);

  const openContactModal = () => {
    closeFreeTrialModal();
    setIsContactModalOpen(true);
  };

  const closeContactModal = () => setIsContactModalOpen(false);

  const { t, i18n } = useTranslation(); // Destructure t and i18n from useTranslation

  return (
    <div className="container-fluid flex flex-col gap-10 lg:gap-0">
      <div className="flex flex-row items-center justify-between gap-5 pt-10">
        <div className="flex items-start flex-col gap-3 w-full lg:w-3/6">
          <h1 className="text-2xl md:text-3xl lg:text-4xl font-semibold">
            {title}
          </h1>
          <div className="flex flex-wrap gap-3">
            {tags?.map((item, index) => {
              return (
                <div className="bg-tags px-3 py-2 rounded-3xl" key={index}>
                  {item}
                </div>
              );
            })}
          </div>
          <p className="font-normal text-gray-500 pb-10 pt-3">{description}</p>

          <div className="flex flex-col sm:flex-row items-start sm:items-center gap-2 pb-5">
            <Button
              label={t("button.enroll_now")}
              variant="primary"
              size="large"
              onClick={() => openContactModal()}
            />
            <a
              href="https://scheduler.zoom.us/darstop/free-trial-lesson"
              target="_blank"
            >
              <Button
                label={t("banner.freeButton")}
                variant="outline"
                size="large"
              ></Button>
            </a>
          </div>
        </div>
        <div className="hidden lg:flex w-2/5 relative z-0">
          <img src={background} alt="image" title={title} className="w-full" />

          {/* absolute images */}
          {/* <img
            src={cursor}
            alt="cursor"
            className="absolute top-1/4 right-0 w-16 z-[-1]"
          />
          <img
            src={play}
            alt="play"
            className="absolute top-0 right-8 w-20 z-[-1]"
          />
          <img
            src={file}
            alt="file"
            className="absolute top-0 left-8 w-20 z-[-1]"
          /> */}
        </div>
      </div>
      <div className="border-2 bg-white border-main border-opacity-30 p-5 sm:p-10 rounded-3xl flex items-start flex-col gap-5 relative">
        <h2 className="font-semibold text-xl ">
          {t("advantages", { ns: "classes" })}
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-5">
          {advantages.map((item, index) => {
            return (
              <div className="flex items-center gap-2" key={index}>
                <img
                  src={item.icon}
                  alt={item.name}
                  className="w-14 h-14 rounded-full"
                />
                <h3 className="text-base opacity-80">{item.name}</h3>
              </div>
            );
          })}
        </div>
      </div>
      {isFreeTrialModalOpen && (
        <FreeTrialModal
          closeModal={closeFreeTrialModal}
          openContactModal={openContactModal}
        />
      )}
      {isContactModalOpen && (
        <ContactDetailsModal closeModal={closeContactModal} />
      )}
    </div>

    // <div className="bg-purple-100 py-12 px-6">
    //   <div className="max-w-screen-2xl mx-auto flex flex-col md:flex-row items-center">
    //     <div className="md:w-1/2">
    //       <h1 className="text-4xl font-bold text-gray-800">{title}</h1>
    //       <ul className="mt-4 space-y-2">
    //         <li className="flex items-center text-gray-700">
    //           <span role="img" aria-label="Video">
    //             🎥
    //           </span>{" "}
    //           Engaging live video chat classes
    //         </li>
    //         <li className="flex items-center text-gray-700">
    //           <span role="img" aria-label="Teacher">
    //             🎖
    //           </span>{" "}
    //           Vetted and passionate teachers
    //         </li>
    //         <li className="flex items-center text-gray-700">
    //           <span role="img" aria-label="Confidence">
    //             🚀
    //           </span>{" "}
    //           Build confidence through progress
    //         </li>
    //       </ul>
    //       <div className="mt-4">
    //         <span className="font-bold text-green-600">Excellent</span>
    //         <span className="text-green-600"> ★★★★★ </span>
    //         <span className="text-gray-600">{reviews}</span>
    //       </div>
    //       <button
    //         onClick={openContactModal}
    //         className="bg-orange-500 text-white px-4 py-2 rounded-lg mt-4"
    //       >
    //         Enroll Now
    //       </button>

    //     </div>
    //     <div className="md:w-1/3 mt-8 md:mt-0">
    //       <img
    //         src="./girl-with-laptop.webp"
    //         alt="Hero"
    //         className="rounded-lg shadow-md h-80"
    //       />
    //     </div>
    //   </div>
    // </div>
  );
};

export default ClassListingHeroSection;
