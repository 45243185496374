import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import axiosInstance from "../../common/api/axios";
import WhatsAppIcon from "../assets/whatsapp.png";
import TelegramIcon from "../assets/Telegram-logo.png";
import ChatIcon from "../assets/Telegram-logo.png";

const Consultations: React.FC = () => {
  const { t } = useTranslation();

  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setMessage(""); // Clear previous messages
    setError(""); // Clear previous errors

    try {
      const response = await axiosInstance.post("/mail", {
        name,
        phoneNumber: phone,
        email,
        message: "",
      });
      if (response.status === 201 || response.status === 204) {
        setMessage(t("form.successMessage"));
        setName("");
        setPhone("");
        setEmail("");
      } else {
        setError(t("form.errorMessage"));
      }
    } catch (err) {
      setError(t("form.errorMessage"));
    }
  };

  return (
    <>
      <div className="flex justify-center items-center min-h-screen bg-purple-100">
        <form
          onSubmit={handleSubmit}
          className="bg-purple-200 p-8 rounded-lg shadow-lg w-full max-w-md"
        >
          <h2 className="text-2xl font-bold mb-4 text-center text-gray-800">
            {t("form.title", { ns: "classes" })}
          </h2>
          <p className="text-center text-gray-600 mb-6">
            {t("form.body", { ns: "classes" })}
          </p>

          <input
            type="text"
            name="name"
            placeholder={t("form.namePlaceholder")}
            value={name}
            required
            onChange={(e) => setName(e.target.value)}
            className="w-full p-3 mb-4 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-purple-400"
          />

          <input
            type="email"
            name="email"
            placeholder={t("form.emailPlaceholder")}
            value={email}
            required
            onChange={(e) => setEmail(e.target.value)}
            className="w-full p-3 mb-4 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-purple-400"
          />

          <div className="relative mb-4">
            {/* <span className="absolute left-4 top-3 text-gray-500">+998</span> */}
            <input
              type="tel"
              name="phone"
              placeholder={t("form.phonePlaceholder")}
              value={phone}
              required
              onChange={(e) => setPhone(e.target.value)}
              className="w-full p-3 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-purple-400"
            />
          </div>

          {message && (
            <p className="my-4  text-green-500 text-center">{message}</p>
          )}

          {error && <p className="mt-4 text-red-500 text-center">{error}</p>}

          <button
            type="submit"
            className="w-full bg-purple-500 text-white p-3 rounded-lg font-semibold hover:bg-purple-600 transition duration-300"
          >
            {t("form.signUp", { ns: "classes" })}
          </button>

          <p className="text-center text-gray-600 mt-4 text-sm">
            {t("form.offer", { ns: "classes" })}
            {/* Отправляя форму, вы соглашаетесь с{" "}
            <a href="#" className="text-purple-500 underline">
              офертой
            </a>{" "}
            и даёте согласие на обработку ваших{" "}
            <a href="#" className="text-purple-500 underline">
              персональных данных
            </a>
            . */}
          </p>
        </form>
      </div>
    </>
  );
};

export default Consultations;
